import { connect } from 'react-redux';
import Meeting from '../presentations/Meeting';

const mapStateToProps = (state) => ({
  isLoadingReducer: state.isLoadingReducer,
});

const MeetingContainer = connect(mapStateToProps, {})(Meeting);

export default MeetingContainer;
