import { toast } from 'react-toastify';
// import exceptionHandler from '../utils/exceptionHandler';
import http from '../shared/httpService';
import contentLoading from './loading.action';
import actionTypes from '../constants/client.constants';
import exceptionHandler from '../shared/exeptionHandler';

export const getUserInfoSuccess = (user) => {
  return {
    type: actionTypes.FETCH_USER_INFO_SUCCESS,
    user,
  };
};

export const getUserInfoFailure = () => {
  return {
    type: actionTypes.FETCH_USER_INFO_FAILURE,
  };
};

export const getUserInfo = () => {
  return async (dispatch) => {
    dispatch(contentLoading);
    try {
      if (!navigator.onLine) {
        toast.error('Please check your internet connection');
      }
      const { data } = await http.get('/auth/userinfo');
      dispatch(getUserInfoSuccess(data));
      return data;
    } catch (ex) {
      dispatch(getUserInfoFailure());
      return exceptionHandler(ex);
    }
  };
};


export const confirmPaymentSuccess = (data) => {
  return {
    type: actionTypes.CONFIRM_PAYMENT_SUCCESS,
    data,
  };
}

export const confirmPaymentFailure = () => {
  return {
    type: actionTypes.CONFIRM_PAYMENT_FAILURE,
  };
}

export const confirmPayment = (data) => {
  return async (dispatch) => {
    dispatch(contentLoading);
    try {
      if (!navigator.onLine) {
        toast.error('Please check your internet connection');
      }
      const { data: response } = await http.post('/subscription/mpesa/confirmation', data);
      dispatch(confirmPaymentSuccess(response));
      return response;
    } catch (ex) {
      dispatch(confirmPaymentFailure());
      return exceptionHandler(ex);
    }
  };
}