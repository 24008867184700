import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from 'reactstrap';
import classnames from 'classnames';

function LoginForm({
  emailFocus,
  onKeyDown,
  passwordFocus,
  handleChange,
  handleClick,
  handleNormalChange,
}) {
  return (
    <>
      <CardHeader>
        <CardImg alt="..." src={require('../../../assets/img/square-purple-1.png')} />
        <CardTitle tag="h4">Login</CardTitle>
      </CardHeader>
      <CardBody>
        <InputGroup
          className={classnames('input-lg', {
            'input-group-focus': emailFocus,
          })}
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="tim-icons icon-email-85" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Username..."
            type="text"
            name="email"
            onChange={(e) => handleChange(e)}
            onKeyDown={onKeyDown}
            onFocus={(e) => handleNormalChange('emailFocus', true)}
            onBlur={(e) => handleNormalChange('emailFocus', false)}
          />
        </InputGroup>
        <InputGroup
          className={classnames('input-lg', {
            'input-group-focus': passwordFocus,
          })}
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="tim-icons icon-lock-circle" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Password..."
            type="password"
            onChange={(e) => handleChange(e)}
            onKeyDown={onKeyDown}
            name="password"
            onFocus={(e) => handleNormalChange('passwordFocus', true)}
            onBlur={(e) => handleNormalChange('passwordFocus', false)}
          />
        </InputGroup>
      </CardBody>
      <CardFooter className="text-center">
        <Button
          block
          className="btn-round"
          color="primary"
          href="#pablo"
          onClick={handleClick}
          size="lg"
        >
          Login
        </Button>
      </CardFooter>
      <div className="pull-right mr-3 mb-3">
        <h6>
          <a className="link footer-link" href="/forgot-password">
            Can't login?
          </a>
        </h6>
      </div>
      <div className="pull-left ml-3 mb-3">
        <h6>
          <a className="link footer-link" href="/">
            Home
          </a>
        </h6>
      </div>
    </>
  );
}

export default LoginForm;
