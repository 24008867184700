import { connect } from 'react-redux';
import NewApplication from '../presentations/Application';
import { postApplication } from '../../actions/applicationActions';

const mapStateToProps = ({ application }) => {
  return {
    application,
  };
};

const Application = connect(mapStateToProps, { postApplication })(NewApplication);

export default Application;
