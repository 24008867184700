import exceptionHandler from '../shared/exeptionHandler';

export const SET_PAGE_AND_LIMIT = 'SET_PAGE_AND_LIMIT';
export const SET_PAGINATION_REQUEST = 'SET_PAGINATION_REQUEST';

export const setPageAndLimit = (page: number, limit: number, query?: string): any => {
  console.log('setPageAndLimit', page, limit, query);
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SET_PAGE_AND_LIMIT,
        payload: {
          page,
          limit,
          query,
        },
      });
    }
    catch (ex) {
      return exceptionHandler(ex);
    }
    // dispatch({
    //   type: SET_PAGE_AND_LIMIT,
    //   payload: { page, limit, query },
    // });
  };
};

export const setPagination = (ope: string, filter: string): any => {
  return (dispatch: any) => {
    dispatch({
      type: SET_PAGINATION_REQUEST,
      payload: { ope, filter },
    });
  };
};
