import React, { Component } from 'react';

import {
  Card,
  Form,
  Container,
  Col,
} from 'reactstrap';

import LoginForm from './forms/LoginForm';

class ClientLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { email: '', password: '' },
      passwordFocus: false,
      emailFocus: false,
    };
  }

  handleClick = async (e) => {
    e.preventDefault();
    const { data } = this.state;
    const { login: loginUser } = this.props;
    await loginUser(data, this.props);
  };

  handleChange = (e) => {
    const { data } = this.state;
    this.setState({
      data: { ...data, [e.target.name]: e.target.value },
    });
  };

  handleNormalChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      this.handleClick(event);
    }
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.body.classList.add('login-page');
  }
  componentWillUnmount() {
    document.body.classList.remove('login-page');
  }

  render() {
    return (
      <>
        <div className="page-header">
          <div className="squares square1" />
          <div className="squares square2" />
          <div className="squares square3" />
          <div className="squares square4" />
          <div className="squares square5" />
          <div className="squares square6" />
          <div className="page-header-image" />
          <Container>
            <Col className="mx-auto" lg="5" md="8">
              <Card className="card-login">
                <Form action="" className="form" method="">
                  <LoginForm
                    emailFocus={this.state.emailFocus}
                    onKeyDown={this.onKeyDown}
                    passwordFocus={this.state.passwordFocus}
                    handleChange={this.handleChange}
                    handleNormalChange={this.handleNormalChange}
                    handleClick={this.handleClick}
                  />
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

// ClientLogin.propTypes = {
//   login: PropTypes.func.isRequired,
// };

export default ClientLogin;
