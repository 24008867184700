import { connect } from 'react-redux';
import { login } from '../../actions/authActions';
import ClientLogin from '../presentations/ClientLogin';

const mapStateToProps = ({ isLoadingReducer }) => {
    return {
        isLoadingReducer,
    };
};

const ClientLoginContainer = connect(mapStateToProps, { login })(ClientLogin);

export default ClientLoginContainer;
