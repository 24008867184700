import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Input, Row, Col } from 'reactstrap';
import Select from 'react-select';
import LoadingIcons from 'react-loading-icons';

import CandidateRequirements from '../../containers/CandidateRequirements';

const ApplicationForm = ({
  submitApplication,
  handleInput,
  handleSelect,
  firstName,
  lastName,
  email,
  company,
  title,
  linkedInProfile,
  city,
  professionalLevel,
  howDidYouHearAboutUs,
  formErrors,
  formValid,
  firstNameValid,
  lastNameValid,
  emailValid,
  companyValid,
  titleValid,
  linkedInProfileValid,
  cityValid,
  submitting,
}) => (
  <>
    <div>
      <header>
        <h2 className="text-uppercase">Join the waitlist</h2>
      </header>
      <hr className="line-info" />
      <br />
      <CandidateRequirements />
      <Row>
        <Col className="ml-auto mr-auto text-center" md="6">
          <h4 className="description">Complete the form below to join the waitlist.</h4>
          <div className="section-space" />
        </Col>
      </Row>
      <Row>
        <Col className="align-self-center" md="3">
          <label className="labels" htmlFor="#firstName">
            First Name
          </label>
        </Col>
        <Col className="align-self-center" md="9">
          <FormGroup>
            <Input
              id="firstName"
              name="firstName"
              required="required"
              type="text"
              value={firstName}
              onChange={(e) => handleInput(e)}
              placeholder="Your first name"
            />
          </FormGroup>
          <label style={{ color: '#ec250d' }}>{!firstNameValid && formErrors.firstName}</label>
        </Col>
      </Row>
      <Row>
        <Col className="align-self-center" md="3">
          <label className="labels" htmlFor="#lastName">
            Last Name
          </label>
        </Col>
        <Col className="align-self-center" md="9">
          <FormGroup>
            <Input
              id="lastName"
              name="lastName"
              required="required"
              type="text"
              value={lastName}
              onChange={(e) => handleInput(e)}
              placeholder="Your last name"
            />
          </FormGroup>
          <label style={{ color: '#ec250d' }}>{!lastNameValid && formErrors.lastName}</label>
        </Col>
      </Row>
      <Row>
        <Col className="align-self-center" md="3">
          <label className="labels" htmlFor="#lastName">
            Email
          </label>
        </Col>
        <Col className="align-self-center" md="9">
          <FormGroup>
            <Input
              id="email"
              name="email"
              required="required"
              type="email"
              value={email}
              onChange={(e) => handleInput(e)}
              placeholder="johndoe@mail.com"
            />
          </FormGroup>
          <label style={{ color: '#ec250d' }}>{!emailValid && formErrors.email}</label>
        </Col>
      </Row>
      <Row>
        <Col className="align-self-center" md="3">
          <label className="labels" htmlFor="#lastName">
            Company
          </label>
        </Col>
        <Col className="align-self-center" md="9">
          <FormGroup>
            <Input
              id="company"
              name="company"
              required="required"
              type="text"
              value={company}
              onChange={(e) => handleInput(e)}
              placeholder="eg. Google"
            />
          </FormGroup>
          <label style={{ color: '#ec250d' }}>{!companyValid && formErrors.company}</label>
        </Col>
      </Row>
      <Row>
        <Col className="align-self-center" md="3">
          <label className="labels" htmlFor="#lastName">
            Title
          </label>
        </Col>
        <Col className="align-self-center" md="9">
          <FormGroup>
            <Input
              id="title"
              name="title"
              required="required"
              type="text"
              value={title}
              onChange={(e) => handleInput(e)}
              placeholder="e.g. CEO"
            />
          </FormGroup>
          <label style={{ color: '#ec250d' }}>{!titleValid && formErrors.title}</label>
        </Col>
      </Row>

      <Row>
        <Col className="align-self-center" md="3">
          <label className="labels" htmlFor="#lastName">
            LinkedIn Profile URL:
          </label>
        </Col>
        <Col className="align-self-center" md="9">
          <FormGroup>
            <Input
              id="linkedInProfile"
              name="linkedInProfile"
              required="required"
              type="text"
              value={linkedInProfile}
              onChange={(e) => handleInput(e)}
              placeholder="https://www.linkedin.com/in/your-profile-url"
            />
          </FormGroup>
          <label style={{ color: '#ec250d' }}>
            {!linkedInProfileValid && formErrors.linkedInProfile}
          </label>
        </Col>
      </Row>

      <Row>
        <Col className="align-self-center" md="3">
          <label className="labels" htmlFor="#lastName">
            City
          </label>
        </Col>
        <Col className="align-self-center" md="9">
          <FormGroup>
            <Input
              id="city"
              name="city"
              required="required"
              type="text"
              value={city}
              placeholder="eg. Nairobi"
              onChange={(e) => handleInput(e)}
            />
          </FormGroup>
          <label style={{ color: '#ec250d' }}>{!cityValid && formErrors.city}</label>
        </Col>
      </Row>

      <Row>
        <Col className="align-self-center" md="3">
          <label className="labels">What is your professional level:</label>
        </Col>
        <Col className="align-self-center" md="9">
          <FormGroup>
            <Select
              id="professionalLevel"
              name="professionalLevel"
              className="react-select react-select-info"
              classNamePrefix="react-select"
              placeholder="What is your professional level"
              value={professionalLevel}
              onChange={(e) => handleSelect('professionalLevel', e)}
              options={[
                {
                  value: '',
                  label: 'Your professional level',
                  isDisabled: true,
                },
                {
                  value: 'CEO/President',
                  label: 'CEO/President',
                },
                { value: 'C-Level', label: 'C-Level' },
                { value: 'SVP', label: 'SVP' },
                { value: 'VP', label: 'VP' },
                { value: 'Direcor', label: 'Director' },
                { value: 'Manager', label: 'Manager' },
                { value: 'Founder', label: 'Founder' },
              ]}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col className="align-self-center" md="3">
          <label className="labels">How did you hear about Powervel:</label>
        </Col>
        <Col className="align-self-center" md="9">
          <FormGroup>
            <Select
              id="howDidYouHearAboutUs"
              name="howDidYouHearAboutUs"
              className="react-select react-select-info"
              classNamePrefix="react-select"
              placeholder="How did you hear about Powervel"
              value={howDidYouHearAboutUs}
              onChange={(e) => handleSelect('howDidYouHearAboutUs', e)}
              options={[
                {
                  value: '',
                  label: 'How did you hear about Powervel',
                  isDisabled: true,
                },
                { value: '2', label: 'Powervel Member' },
                { value: '3', label: 'LinkedIn' },
                { value: '3', label: 'Colleague' },

                { value: '3', label: 'Friend/Individual' },
                {
                  value: '3',
                  label: 'Internal Company Initiatives',
                },
                { value: '3', label: 'Podcast' },
                { value: '3', label: 'Other' },
              ]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="6">
          {submitting ? (
            <Button color="info" disabled>
              <LoadingIcons.Puff stroke="#98ff98" strokeOpacity={0.125} speed={0.75} />
            </Button>
          ) : (
            <Button
              color="info"
              type="submit"
              disabled={!formValid}
              onClick={() => submitApplication()}
            >
              Submit
            </Button>
          )}
        </Col>
      </Row>
    </div>
  </>
);

ApplicationForm.propTypes = {
  submitApplication: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  formValid: PropTypes.bool.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  howDidYouHearAboutUs: PropTypes.object.isRequired,
};

export default ApplicationForm;
