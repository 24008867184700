const actionType = {
    FETCH_QUESTIONS_SUCCESS: 'FETCH_QUESTIONS_SUCCESS',
    FETCH_QUESTIONS_FAILURE: 'FETCH_QUESTIONS_FAILURE',
    FETCH_SINGLE_QUESTION_SUCCESS: 'FETCH_SINGLE_QUESTION_SUCCESS',
    FETCH_SINGLE_QUESTION_FAILURE: 'FETCH_SINGLE_QUESTION_FAILURE',
    POST_ANSWER_SUCCESS: 'POST_ANSWER_SUCCESS',
    POST_ANSWER_FAILURE: 'POST_ANSWER_FAILURE',
    POST_QUESTION_SUCCESS: 'POST_QUESTION_SUCCESS',
    POST_QUESTION_FAILURE: 'POST_QUESTION_FAILURE',
    POST_COMMENT_SUCCESS: 'POST_COMMENT_SUCCESS',
    POST_COMMENT_FAILURE: 'POST_COMMENT_FAILURE'
  };
  
  export default actionType;