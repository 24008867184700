import React from 'react';
import Axios from 'axios';
// reactstrap components
import {  TabContent, TabPane, Container, Col } from 'reactstrap';

// core components
import ColorNavbar from '../common/ColorNavbar.jsx';
import DemoFooter from '../common/DemoFooter.jsx';
import PropTypes from 'prop-types';
import ApplicationForm from './forms/ApplicationForm.jsx';

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileTabs: 1,
      tags: ['HTML5', 'CSS3', 'JavaScript', 'React'],
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      title: '',
      linkedInProfile: '',
      city: '',
      professionalLevel: '',
      howDidYouHearAboutUs: '',
      formErrors: {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        title: '',
        linkedInProfile: '',
        city: '',
      },
      formValid: false,
      firstNameValid: false,
      lastNameValid: false,
      emailValid: false,
      companyValid: false,
      titleValid: false,
      linkedInProfileValid: false,
      cityValid: false,
      submitting: false
    };
  }

  async submitApplication() {
    this.setState({ submitting: true });
    const {
      firstName,
      lastName,
      email,
      company,
      title,
      linkedInProfile,
      city,
      professionalLevel,
      howDidYouHearAboutUs,
    } = this.state;
    const { postApplication } = this.props;
    // this can be used later
    // await Axios.post(process.env.REACT_APP_SPREADSHEET_URL, {
    //   firstName,
    //   lastName,
    //   email,
    //   company,
    //   title,
    //   linkedInProfile,
    //   city,
    //   professionalLevel: professionalLevel.label,
    //   howDidYouHearAboutUs: howDidYouHearAboutUs.label
    // })
    await postApplication({
      firstName,
      lastName,
      email,
      company,
      title,
      linkedInProfile,
      city,
      professionalLevel: professionalLevel.label,
      howDidYouHearAboutUs: howDidYouHearAboutUs.label,
    });
    this.setState({ submitting: false, firstName: '', lastName: '', email: '', company: '', title: '', linkedInProfile: '', city: '', professionalLevel: '', howDidYouHearAboutUs: '' });
  }

  validateFields(field, value) {
    let firstNameValid = this.state.firstNameValid;
    let lastNameValid = this.state.lastNameValid;
    let emailValid = this.state.emailValid;
    let companyValid = this.state.companyValid;
    let titleValid = this.state.titleValid;
    let linkedInProfileValid = this.state.linkedInProfileValid;
    let cityValid = this.state.cityValid;
    let fieldValidationErrors = this.state.formErrors;

    switch (field) {
      case 'firstName':
        firstNameValid = value.length > 0;
        fieldValidationErrors.firstName = 'First Name is required';
        break;
      case 'lastName':
        lastNameValid = value.length > 0;
        fieldValidationErrors.lastName = 'Last Name is required';
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid, user@mail.com';
        break;
      case 'company':
        companyValid = value.length > 0;
        fieldValidationErrors.company = 'Company is required';
        break;
      case 'title':
        titleValid = value.length > 0;
        fieldValidationErrors.title = 'Title is required';
        break;
      case 'linkedInProfile':
        linkedInProfileValid = value.length > 0;
        fieldValidationErrors.linkedInProfile = 'LinkedIn Profile is required';
        break;
      case 'city':
        cityValid = value.length > 0;
        fieldValidationErrors.city = 'City is required';
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        firstNameValid,
        lastNameValid,
        emailValid,
        companyValid,
        titleValid,
        linkedInProfileValid,
        cityValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.firstNameValid &&
        this.state.lastNameValid &&
        this.state.emailValid &&
        this.state.companyValid &&
        this.state.titleValid &&
        this.state.linkedInProfileValid &&
        this.state.cityValid,
    });
  }

  handleInput(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    this.validateFields(name, value);
  }

  handleSelect(label, value) {
    this.setState({ [label]: value });
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add('account-settings');
  }
  componentWillUnmount() {
    document.body.classList.remove('account-settings');
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index,
    });
  };
  handleTags = (regularTags) => {
    this.setState({ tags: regularTags });
  };
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="section">
            <Container>
              <Col className="ml-auto mr-auto" md="8">
                <div className="section">
                  <TabContent activeTab={'profile' + this.state.profileTabs}>
                    <TabPane tabId="profile1">
                      <ApplicationForm
                        submitApplication={this.submitApplication.bind(this)}
                        handleInput={this.handleInput.bind(this)}
                        handleSelect={this.handleSelect.bind(this)}
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        email={this.state.email}
                        company={this.state.company}
                        title={this.state.title}
                        linkedInProfile={this.state.linkedInProfile}
                        city={this.state.city}
                        professionalLevel={this.state.professionalLevel}
                        howDidYouHearAboutUs={this.state.howDidYouHearAboutUs}
                        formErrors={this.state.formErrors}
                        formValid={this.state.formValid}
                        firstNameValid={this.state.firstNameValid}
                        lastNameValid={this.state.lastNameValid}
                        emailValid={this.state.emailValid}
                        companyValid={this.state.companyValid}
                        titleValid={this.state.titleValid}
                        linkedInProfileValid={this.state.linkedInProfileValid}
                        cityValid={this.state.cityValid}
                        submitting={this.state.submitting}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Container>
          </div>
          <DemoFooter />
        </div>
      </>
    );
  }
}

Application.propTypes = {
  postApplication: PropTypes.func.isRequired,
};

export default Application;
