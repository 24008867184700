import React from 'react';

// reactstrap components
import { Button, Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';

// core components
import ColorNavbar from '../common/ColorNavbar.jsx';
import DemoFooter from '../common/DemoFooter.jsx';
import Testimonials from './Testimonials';
import { Link } from 'react-router-dom';
import HomePageInsights from './HomePageInsights.jsx';
import Partners from './Partners.jsx';
// import { chartExample1 } from 'variables/charts.js';

class LandingPage extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add('landing-page');
  }
  componentWillUnmount() {
    document.body.classList.remove('landing-page');
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header header-filter">
            <div
              className="page-header-image"
              data-parallax={true}
              style={{
                backgroundImage: 'url(https://images.unsplash.com/photo-1462826303086-329426d1aef5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)',
              }}
            />
            <Container>
              <Row>
                <Col lg="6" md="6">
                  <h1 className="text-white title">
                    Leaders' private social
                    <br />
                    <span className="text-white">network</span>
                  </h1>
                  <p className="text-white mb-3">
                    Gain access to an impressive network of senior executives from Fortune companies
                    and well-established startups from a wide range of professions, fields, and
                    organizations.
                  </p>
                  <div className="btn-wrapper mb-3">
                    <Link to="/apply">
                      <p className="category text-success d-inline">Get Started</p>
                      <Button className="btn-link" color="success" href="#pablo" size="sm">
                        <i className="tim-icons icon-minimal-right" />
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section section-basic-components">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="5" md="7">
                  <h1 className="title">A Community Centered Around Growth</h1>
                  <h5 className="category text-primary">
                    <strong>Our Advantage</strong>
                  </h5>
                  <p className="description mt-5">
                    With Powervel coaching, you'll gain the confidence and knowledge to push your
                    drive to new heights. We have mentored over 50 C-Level executives and
                    entrepreneurs at all phases of their firms using a proven methodology that
                    promotes a healthy team culture.
                  </p>
                </Col>
                <Col lg="6" md="12">
                  <div className="image-container">
                    {/* SVG Illustration */}
                    <figure className="ie-non-standard-hero-shape">
                      <svg
                        x="0px"
                        y="0px"
                        viewBox="10 12 878.9 907"
                        style={{ enableBackground: 'new 10 12 878.9 907' }}
                        xmlSpace="preserve"
                        className="injected-svg js-svg-injector"
                      >
                        <g>
                          <defs>
                            <path
                              id="firstShape"
                              d="M329.15,403.44c30.22-62,26.51-223.94,94.06-268.46C479,98.23,560.16,257,700.68,151.61c71.25-53.44,85.54,81,160.38,172.6C1008.5,504.74,881.5,639.14,825.35,686.6c-62.54,52.85-246.14,24.42-386.7,79.28S214.07,834,202.07,702C190.39,573.57,288.69,486.43,329.15,403.44Z"
                            />
                          </defs>
                          <clipPath id="secondShape">
                            <use xlinkHref="#firstShape" style={{ overflow: 'visible' }} />
                          </clipPath>
                          <g clipPath="url(#secondShape)">
                            <image
                              id="imageShape1"
                              style={{ overflow: 'visible' }}
                              width="900"
                              height="900"
                              xlinkHref="https://images.unsplash.com/photo-1545184180-25d471fe75eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                              transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                            />
                          </g>
                        </g>
                        <g>
                          <defs>
                            <path
                              id="thirdShape"
                              d="M337.17,855.62c-7.81-35.46,42.38-43.95,63.66-52.44,24.39-9.74,135.86-48,192.58-52.52,64.22-5.13,190.21-26.84,160.46,35.34-19.76,41.3-51.47,64.52-87.63,62.33-46.36-2.81-101.56,4.39-150.8,44C448.53,946.08,450.93,865,412,868,372.28,871,340.79,872.08,337.17,855.62Z"
                            />
                          </defs>
                          <clipPath id="fourthShape">
                            <use xlinkHref="#thirdShape" style={{ overflow: 'visible' }} />
                          </clipPath>
                          <g transform="matrix(1 0 0 1 0 0)" clipPath="url(#fourthShape)">
                            <image
                              id="imageShape2"
                              style={{ overflow: 'visible' }}
                              width="900"
                              height="1000"
                              xlinkHref="https://images.unsplash.com/photo-1545184180-25d471fe75eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                              transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                            >
                              {' '}
                            </image>
                          </g>
                        </g>
                      </svg>
                    </figure>
                    {/* End SVG Illustration */}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>{' '}
          <Partners/>
          <HomePageInsights />
          <section>
            <div className="section about-description">
              {/* <img alt="..." className="path path4" src={require('../../assets/img/path4.png')} /> */}
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center mt-4" md="4">
                    <Button
                      className="btn-simple mt-4"
                      color="warning"
                      href="/membership"
                      size="lg"
                    >
                      Explore Membership
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <DemoFooter />
        </div>
      </>
    );
  }
}

export default LandingPage;
