import axios from 'axios';
import { getToken } from './authService';

const token = getToken();

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: token },
});

export default http;
