import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { decodeToken } from '../../shared/authService';
import ClientLayout from './ClientLayout';

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return decodeToken() == null ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ) : (
          <ClientLayout component={Component} props={props}></ClientLayout>
        );
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  location: null,
  render: null,
};

ProtectedRoute.propTypes = {
  component: PropTypes.shape().isRequired,
  render: PropTypes.shape(),
  location: PropTypes.shape(),
};

export default ProtectedRoute;
