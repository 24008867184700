import React from 'react';
import Loader from '../../shared/Loader';
import ClientNavbar from '../common/ClientNavbar';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Media,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import LoadingIcons from 'react-loading-icons';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

class SingleQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: '',
      comment: '',
      submittingAnswer: false,
      edittingAnswer: false,
      modalNewComment: false,
      newComment: '',
      submittingComment: false,
      answerId: '',
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    document.body.classList.add('blog-post');
    const { getUserInfo, getSingleQuestion } = this.props;
    getSingleQuestion(this.props.match.params.id);
  }
  componentWillUnmount() {
    document.body.classList.remove('blog-post');
  }

  handlePostAnswer = async (e) => {
    e.preventDefault();
    this.setState({ submittingAnswer: true });
    const { postAnswer, qaReducer, getSingleQuestion } = this.props;
    await postAnswer(this.props.match.params.id, qaReducer.qa.Client.id, this.state.answer);
    this.setState({ answer: '' });
    await getSingleQuestion(this.props.match.params.id);
    this.setState({ submittingAnswer: false });
  };

  handlePostComment = async (e) => {
    e.preventDefault();
    this.setState({ submittingComment: true });
    const { postComment, qaReducer, getSingleQuestion } = this.props;
    await postComment(this.state.answerId, qaReducer.qa.Client.id, {
      comment: this.state.newComment,
    });
    this.setState({ newComment: '', answerId: 0 });
    await getSingleQuestion(this.props.match.params.id);
    this.setState({ submittingComment: false, modalNewComment: false });
  };

  toggleNewCommentModal = async (e, answerId) => {
    await this.setState({
      modalNewComment: !this.state.modalNewComment,
      answerId: answerId,
    });
  };

  render() {
    const { isLoadingReducer, qaReducer, postAnswer, getSingleQuestion } = this.props;
    const { loader: isLoading } = isLoadingReducer;
    return (
      <>
        <Modal
          isOpen={this.state.modalNewComment}
          toggle={this.toggleNewCommentModal}
          modalClassName="modal-login"
        >
          <Card className="card-login">
            <Form action="" className="form" method="">
              <CardHeader>
                <h3 className="title text-center">Comment</h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={this.toggleNewCommentModal}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Input
                    placeholder="Comment..."
                    rows="4"
                    type="textarea"
                    onChange={(e) => this.onChange(e)}
                    name="newComment"
                    value={this.state.newComment}
                  />
                </FormGroup>
              </CardBody>
              <CardFooter className="text-center">
                {this.state.submittingComment ? (
                  <Button block className="btn-round" color="primary" disabled size="lg">
                    <LoadingIcons.Puff stroke="#98ff98" strokeOpacity={0.125} speed={0.75} />
                  </Button>
                ) : (
                  <Button
                    block
                    className="btn-round"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => this.handlePostComment(e)}
                    size="lg"
                  >
                    Submit Comment
                  </Button>
                )}
              </CardFooter>
            </Form>
          </Card>
        </Modal>
        <ClientNavbar />
        {isLoading && <Loader />}
        {qaReducer && qaReducer.qa && qaReducer.qa.Client && qaReducer.qa.Client.User && (
          <>
            <div className="section">
              <Container>
                <Col md="12">
                  <h2 className="title text-center">Question</h2>
                  <br />
                  <div className="blogs-1">
                    <Row>
                      <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-blog card-plain blog-horizontal">
                          <Row>
                            <Col lg="4">
                              <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded"
                                    src={
                                      qaReducer.qa.image
                                        ? qaReducer.qa.image
                                        : 'https://images.unsplash.com/photo-1599508704512-2f19efd1e35f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80'
                                    }
                                  />
                                </a>
                              </div>
                            </Col>
                            <Col lg="8">
                              <CardBody>
                                <CardTitle tag="h3">
                                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    {qaReducer.qa.question}
                                  </a>
                                </CardTitle>
                                <p className="card-description">
                                  {qaReducer.qa.description ? qaReducer.qa.description : ''}{' '}
                                </p>
                                <div className="author">
                                  <img
                                    alt="..."
                                    className="avatar img-raised"
                                    src={
                                      qaReducer.qa.Client.User.profilePicture
                                        ? qaReducer.qa.Client.User.profilePicture
                                        : 'https://images.unsplash.com/photo-1634034379073-f689b460a3fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
                                    }
                                  />
                                  <div className="text">
                                    <span className="name">
                                      {qaReducer.qa.Client.User.firstName}{' '}
                                      {qaReducer.qa.Client.User.lastName}
                                    </span>
                                    <div className="meta">
                                      {qaReducer.qa.createdAt
                                        ? `Posted on ${moment(qaReducer.qa.createdAt).format('LL')}`
                                        : ''}{' '}
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Container>
            </div>
            <div className="section section-comments">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" md="8">
                    <div className="media-area">
                      <h3 className="title text-center">
                        {`${qaReducer.qa.Answers.length} Answers`}{' '}
                      </h3>
                      {qaReducer.qa.Answers.length > 0 &&
                        qaReducer.qa.Answers.map((answer) => (
                          <div key={answer.id}>
                            <Media>
                              <a
                                className="pull-left"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <div className="avatar">
                                  <Media
                                    alt="..."
                                    className="img-raised"
                                    src={
                                      answer.Client.User.profilePicture
                                        ? answer.Client.User.profilePicture
                                        : 'https://images.unsplash.com/photo-1634034379073-f689b460a3fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
                                    }
                                  />
                                </div>
                              </a>
                              <Media body>
                                <Media heading tag="h5">
                                  {answer.Client.User.firstName} {answer.Client.User.lastName}{' '}
                                  <small className="text-muted">
                                    · {moment(answer.createdAt).format('MMM Do YY')}{' '}
                                  </small>
                                </Media>
                                <p>{answer.answer}</p>
                                <div className="media-footer">
                                  <Button
                                    className="btn-simple pull-right"
                                    color="primary"
                                    href="#pablo"
                                    id="tooltip871944617"
                                    onClick={(e) => this.toggleNewCommentModal(e, answer.id)}
                                    size="sm"
                                  >
                                    <i className="tim-icons icon-send" />
                                  </Button>
                                  <UncontrolledTooltip delay={0} target="tooltip871944617">
                                    Reply to Comment
                                  </UncontrolledTooltip>
                                  <Button
                                    className="btn-simple pull-right"
                                    color="primary"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    size="sm"
                                  >
                                    <ThumbUpIcon fontSize="2" /> {answer.upvotes}
                                  </Button>
                                  <Button
                                    className="btn-simple pull-right"
                                    color="danger"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    size="sm"
                                  >
                                    <ThumbDownIcon fontSize="2" /> {answer.downvotes}
                                  </Button>
                                </div>

                                {answer.Comments &&
                                  answer.Comments.length > 0 &&
                                  answer.Comments.map((comment) => (
                                    <>
                                      <h3 className="title text-center">Comments</h3>
                                      <Media>
                                        <a
                                          className="pull-left"
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <div className="avatar">
                                            <Media
                                              alt="..."
                                              className="img-raised"
                                              src={
                                                comment.Client.User.profilePicture
                                                  ? comment.Client.User.profilePicture
                                                  : 'https://images.unsplash.com/photo-1634034379073-f689b460a3fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
                                              }
                                            />
                                          </div>
                                        </a>

                                        <Media body>
                                          <Media heading tag="h5">
                                            {comment.Client.User.firstName}{' '}
                                            {comment.Client.User.lastName}
                                            <small className="text-muted">
                                              · {moment(comment.createdAt).format('MMM Do YY')}
                                            </small>
                                          </Media>
                                          <p>{comment.comment}</p>
                                          <div className="media-footer">
                                            <Button
                                              className="btn-simple pull-right"
                                              color="primary"
                                              href="#pablo"
                                              onClick={(e) => e.preventDefault()}
                                              size="sm"
                                            >
                                              <ThumbUpIcon fontSize="2" /> {comment.upvotes}
                                            </Button>
                                            <Button
                                              className="btn-simple pull-right"
                                              color="danger"
                                              href="#pablo"
                                              onClick={(e) => e.preventDefault()}
                                              size="sm"
                                            >
                                              <ThumbDownIcon fontSize="2" /> {comment.downvotes}
                                            </Button>
                                          </div>
                                        </Media>
                                      </Media>
                                    </>
                                  ))}
                              </Media>
                            </Media>
                          </div>
                        ))}
                    </div>
                    <h3 className="title text-center">Post an answer</h3>
                    <Media className="media-post">
                      <a
                        className="pull-left author"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="avatar">
                          <Media
                            alt="..."
                            className="img-raised"
                            src={
                              qaReducer.qa.Client.User.profilePicture
                                ? qaReducer.qa.Client.User.profilePicture
                                : 'https://images.unsplash.com/photo-1634034379073-f689b460a3fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
                            }
                          />
                        </div>
                      </a>
                      <Media body>
                        <Input
                          placeholder="Write a nice answer..."
                          rows="4"
                          type="textarea"
                          onChange={(e) => this.onChange(e)}
                          name="answer"
                          value={this.state.answer}
                        />
                        <div className="media-footer">
                          {this.state.submittingAnswer ? (
                            <Button color="primary" className="pull-right" disabled>
                              <LoadingIcons.Puff
                                stroke="#98ff98"
                                strokeOpacity={0.125}
                                speed={0.75}
                              />
                            </Button>
                          ) : (
                            <Button
                              className="pull-right"
                              color="primary"
                              href="#pablo"
                              onClick={(e) => this.handlePostAnswer(e)}
                            >
                              Post Answer
                            </Button>
                          )}
                        </div>
                      </Media>
                    </Media>
                    {/* end media-post */}
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}
      </>
    );
  }
}

export default SingleQuestion;
