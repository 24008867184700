import { toast } from 'react-toastify';
// import exceptionHandler from '../utils/exceptionHandler';
import http from '../shared/httpService';
import contentLoading from './loading.action';
import actionTypes from '../constants/qa.constants';
import exceptionHandler from '../shared/exeptionHandler';

export const getQuestionsListSuccess = (user) => {
  return {
    type: actionTypes.FETCH_QUESTIONS_SUCCESS,
    user,
  };
};

export const getQuestionsListFailure = () => {
  return {
    type: actionTypes.FETCH_QUESTIONS_FAILURE,
  };
};

export const getQuestionsList = (filter) => {
  return async (dispatch) => {
    dispatch(contentLoading);
    try {
      if (!navigator.onLine) {
        toast.error('Please check your internet connection');
      }
      const { limit, page, question } = filter;
      const { data } = await http.get(
        `/qa/questions?page=${page}&limit=${limit}${question ? `&question=${question}` : ''}`
      );
      dispatch(getQuestionsListSuccess(data));
      return data;
    } catch (ex) {
      dispatch(getQuestionsListFailure());
      return exceptionHandler(ex);
    }
  };
};

export const getSingleQuestionSuccess = (question) => {
  return {
    type: actionTypes.FETCH_SINGLE_QUESTION_SUCCESS,
    question,
  };
};

export const getSingleQuestionFailure = () => {
  return {
    type: actionTypes.FETCH_SINGLE_QUESTION_FAILURE,
  };
};

export const getSingleQuestion = (id) => {
  return async (dispatch) => {
    dispatch(contentLoading);
    try {
      if (!navigator.onLine) {
        toast.error('Please check your internet connection');
      }
      const { data } = await http.get(`/qa/question/${id}`);
      dispatch(getSingleQuestionSuccess(data));
      return data;
    } catch (ex) {
      dispatch(getSingleQuestionFailure());
      return exceptionHandler(ex);
    }
  };
};

// post answer
export const postAnswerSuccess = (question) => {
  return {
    type: actionTypes.POST_ANSWER_SUCCESS,
    question,
  };
};

export const postAnswerFailure = () => {
  return {
    type: actionTypes.POST_ANSWER_FAILURE,
  };
};

export const postAnswer = (questionId, clientId, answer) => {
  return async (dispatch) => {
    dispatch(contentLoading);
    try {
      if (!navigator.onLine) {
        toast.error('Please check your internet connection');
      }
      const { data } = await http.post(`/qa/answer/${questionId}?clientId=${clientId}`, { answer });
      dispatch(postAnswerSuccess(data));
      return data;
    } catch (ex) {
      dispatch(postAnswerFailure());
      return exceptionHandler(ex);
    }
  };
};

export const postNewQuestionSuccess = (question) => {
  return {
    type: actionTypes.POST_QUESTION_SUCCESS,
    question,
  };
};

export const postNewQuestionFailure = () => {
  return {
    type: actionTypes.POST_QUESTION_FAILURE,
  };
};

export const postNewQuestion = (question, description, clientId) => {
  return async (dispatch) => {
    dispatch(contentLoading);
    try {
      if (!navigator.onLine) {
        toast.error('Please check your internet connection');
      }
      const { data } = await http.post('/qa/question', { question, description, clientId });
      dispatch(postAnswerSuccess(data));
      return data;
    } catch (ex) {
      dispatch(postAnswerFailure());
      return exceptionHandler(ex);
    }
  };
};

export const postCommentSuccess = (comment) => {
  return {
    type: actionTypes.POST_COMMENT_SUCCESS,
    comment,
  };
}

export const postCommentFailure = () => {
  return {
    type: actionTypes.POST_COMMENT_FAILURE,
  };
}

export const postComment = (answerId, clientId, comment) => {
  return async (dispatch) => {
    dispatch(contentLoading);
    try {
      if (!navigator.onLine) {
        toast.error('Please check your internet connection');
      }
      const { data } = await http.post(`/qa/comment/${Number(answerId)}/${Number(clientId)}`, comment);
      dispatch(postCommentSuccess(data));
      return data;
    } catch (ex) {
      dispatch(postCommentFailure());
      return exceptionHandler(ex);
    }
  };
}