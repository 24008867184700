import { connect } from 'react-redux';
// import { forgotPassword, verifyOtp, resetPassword } from '../../actions/authActions';
import OurCoaches from '../presentations/OurCoaches';

const mapStateToProps = ({ isLoadingReducer }) => {
  return {
    isLoadingReducer,
  };
};

const OurCoachesContainer = connect(mapStateToProps, {
  //   forgotPassword,
  //   verifyOtp,
  //   resetPassword,
})(OurCoaches);

export default OurCoachesContainer;
