import { toast } from 'react-toastify';
import http from '../shared/httpService';
import contentLoading from './loading.action';
import actionTypes from '../constants/admin.constants';
import exceptionHandler from '../shared/exeptionHandler';

export const postContactUsSuccess = (article) => ({
  type: actionTypes.POST_CONTACT_US_SUCCESS,
  article,
});

export const postContactUsError = () => ({
  type: actionTypes.POST_CONTACT_US_FAILURE,
});

export const postContactUs = (data) => {
  return async (dispatch) => {
    dispatch(contentLoading);
    try {
      const contactUsData = await http.post('/new/contactus', data);
      dispatch(postContactUsSuccess(contactUsData));
      //   @ts-ignore
      toast.success(contactUsData.data.message);
      return contactUsData;
    } catch (err) {
      console.log('err', err);
      dispatch(postContactUsError());
      return exceptionHandler(err);
    }
  };
};
