import actionTypes from '../constants/client.constants';

const initialState = {
  clientInfo: {},
};

const clientInfo = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return { ...state, clientInfo: action.user.data };
    case actionTypes.FETCH_USER_INFO_FAILURE:
      return { ...state };
    case actionTypes.CONFIRM_PAYMENT_SUCCESS:
      return { ...state, clientInfo: action };
    case actionTypes.CONFIRM_PAYMENT_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export default clientInfo;
