import React from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
import Select from 'react-select';
// react plugin used to create switch buttons
import Switch from 'react-bootstrap-switch';
import Moment from 'react-moment';
import ReactDatetime from 'react-datetime';
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from 'reactstrap';

import Loader from '../../shared/Loader';
import { Avatar } from '@mui/material';
import PaymentButton from '../common/PaymentButton';
import ClientNavbar from '../common/ClientNavbar';
import ChangePasswordModal from '../common/ChangePasswordModal';

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileTabs: 1,
      activeColor: 'blue',
      modalChangePassword: false,
      data: {
        newPassword: '',
        oldPassword: '',
      },
    };
    this.toggleModalChangePassword = this.toggleModalChangePassword.bind(this);
  }

  toggleModalChangePassword() {
    this.setState({
      modalChangePassword: !this.state.modalChangePassword,
    });
  }

  componentDidMount() {
    const { getUserInfo } = this.props;
    getUserInfo();

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add('account-settings');
  }
  componentWillUnmount() {
    document.body.classList.remove('account-settings');
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index,
    });
  };
  handleTags = (regularTags) => {
    this.setState({ tags: regularTags });
  };
  handleClick() {
    this.refs.fileInput.click();
  }

  handleSubmitChangePassword = async (e) => {
    await this.props.changeAuthpassword({
      password: this.state.data.newPassword,
      oldPassword: this.state.data.oldPassword,
    });
  };

  handleChange = (e) => {
    const { data } = this.state;
    this.setState({
      data: { ...data, [e.target.name]: e.target.value },
    });
  };

  confirmPayment = async (e, subscriptionId, amountPaid) => {
    e.preventDefault();
    await this.props.confirmPayment({ subscriptionId, amountPaid })
  }

  render() {
    const { clientInfo, isLoadingReducer } = this.props;
    const { loader } = isLoadingReducer;

    return (
      <>
        {loader && <Loader />}
        <ClientNavbar />
        <ChangePasswordModal
          handleChange={this.handleChange}
          handleSubmitChangePassword={this.handleSubmitChangePassword}
          toggle={this.toggleModalChangePassword}
          isOpen={this.state.modalChangePassword}
        />
        <div className="wrapper" ref="wrapper">
          <div className="section">
            <div className="section section-notifications" id="notifications">
              {clientInfo && clientInfo.user && clientInfo.user.changePassword && (
                <Container>
                  <UncontrolledAlert className="alert-with-icon" color="danger">
                    <span data-notify="icon" className="tim-icons icon-lock-circle" />
                    <span>
                      <b>Hey Buddy! -</b>
                      You need to change your password.{' '}
                      <a href="#pablo" onClick={this.toggleModalChangePassword}>
                        Click here to change it!
                      </a>
                    </span>
                  </UncontrolledAlert>
                </Container>
              )}
            </div>
            <Container>
              <Row>
                <Col md="3">
                  <div className="section">
                    {/* User Information */}
                    <section className="text-center">
                      {/* <ImageUpload profilePicture={clientInfo && clientInfo.user && clientInfo.user.profilePicture} avatar addBtnColor="default" /> */}
                      <div className="fileinput text-center">
                        <Avatar
                          alt="Remy Sharp"
                          src={clientInfo && clientInfo.user && clientInfo.user.profilePicture}
                          sx={{ width: 90, height: 90 }}
                        />
                        <br />
                      </div>

                      <h3 className="title">
                        {clientInfo && clientInfo.user && clientInfo.user.firstName}{' '}
                        {clientInfo && clientInfo.user && clientInfo.user.lastName}
                      </h3>
                    </section>
                    {/* User Information */}
                    {/* Profile Sidebar */}
                    <section>
                      <br />
                      <Nav className="flex-column nav-tabs-info" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.profileTabs === 1,
                            })}
                            onClick={(e) => this.toggleTabs(e, 'profileTabs', 1)}
                            href="#pablo"
                          >
                            <i className="tim-icons icon-single-02" /> General
                          </NavLink>
                        </NavItem>
                        <hr className="line-info" />
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.profileTabs === 2,
                            })}
                            onClick={(e) => this.toggleTabs(e, 'profileTabs', 2)}
                            href="#pablo"
                          >
                            <i className="tim-icons icon-credit-card" /> Billing
                          </NavLink>
                        </NavItem> */}
                        {/* <hr className="line-info" /> */}
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.profileTabs === 3,
                            })}
                            onClick={(e) => this.toggleTabs(e, 'profileTabs', 3)}
                            href="#pablo"
                          >
                            <i className="tim-icons icon-lock-circle" /> Security
                          </NavLink>
                        </NavItem>
                        <hr className="line-info" />

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.profileTabs === 5,
                            })}
                            onClick={(e) => this.toggleTabs(e, 'profileTabs', 5)}
                            href="#pablo"
                          >
                            <i className="tim-icons icon-single-copy-04" /> Invoices
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </section>
                    {/* End Profile Sidebar */}
                    {/* Profile Completion */}
                    <br />
                    <br />
                    <br />
                    {/* <section>
                      <div className="progress-container progress-info">
                        <span className="progress-badge">Profile Completion</span>
                        <Progress max="100" value="60">
                          <span className="progress-value">60%</span>
                        </Progress>
                      </div>
                    </section> */}
                    {/* End Profile Completion */}
                  </div>
                </Col>
                <Col className="ml-auto" md="8">
                  <div className="section">
                    <TabContent activeTab={'profile' + this.state.profileTabs}>
                      <TabPane tabId="profile1">
                        <div>
                          <header>
                            <h2 className="text-uppercase">General information</h2>
                          </header>
                          <hr className="line-info" />
                          <br />
                          <Row>
                            <Col className="align-self-center" md="3">
                              <label className="labels" htmlFor="#firstName">
                                First Name
                              </label>
                            </Col>
                            <Col className="align-self-center" md="9">
                              <FormGroup>
                                <Input
                                  id="firstName"
                                  name="firstName"
                                  required="required"
                                  type="text"
                                  disabled
                                  value={clientInfo && clientInfo.user && clientInfo.user.firstName}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="align-self-center" md="3">
                              <label className="labels" htmlFor="#lastName">
                                Last Name
                              </label>
                            </Col>
                            <Col className="align-self-center" md="9">
                              <FormGroup>
                                <Input
                                  id="lastName"
                                  name="lastName"
                                  required="required"
                                  type="text"
                                  disabled
                                  value={clientInfo && clientInfo.user && clientInfo.user.lastName}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="align-self-center" md="3">
                              <label className="labels">I’m</label>
                            </Col>
                            <Col className="align-self-center" md="9">
                              <FormGroup>
                                <Select
                                  className="react-select react-select-info"
                                  classNamePrefix="react-select"
                                  placeholder="Gender"
                                  value={clientInfo && clientInfo.user && clientInfo.user.gender}
                                  onChange={(value) => this.setState({ gender: value })}
                                  disabled
                                  options={[
                                    {
                                      value: '',
                                      label: 'Gender',
                                      isDisabled: true,
                                    },
                                    { value: 'male', label: 'Male' },
                                    { value: 'female', label: 'Female' },
                                  ]}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="align-self-center" md="3">
                              <label className="labels">Birth Date</label>
                            </Col>
                            <Col className="align-self-center" md="9">
                              <FormGroup>
                                <ReactDatetime
                                  inputProps={{
                                    className: 'form-control',
                                    placeholder: 'SelectDate',
                                    readOnly: true,
                                    value:
                                      clientInfo && clientInfo.user && clientInfo.user.birthDate,
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="align-self-center" md="3">
                              <label className="labels" htmlFor="#email">
                                Email
                              </label>
                            </Col>
                            <Col className="align-self-center" md="9">
                              <FormGroup>
                                <Input
                                  value={clientInfo && clientInfo.user && clientInfo.user.email}
                                  id="email"
                                  name="email"
                                  required="required"
                                  type="email"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="align-self-center" md="3">
                              <label className="labels" htmlFor="#location">
                                Your Location
                              </label>
                            </Col>
                            <Col className="align-self-center" md="9">
                              <FormGroup>
                                <Input
                                  value={
                                    clientInfo && clientInfo.client && clientInfo.client.address
                                  }
                                  id="location"
                                  name="location"
                                  required="required"
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="align-self-center" md="3">
                              <label className="labels" htmlFor="#phone">
                                Phone Number
                              </label>
                            </Col>
                            <Col className="align-self-center" md="9">
                              <FormGroup>
                                <Input
                                  value={
                                    clientInfo &&
                                    clientInfo.client &&
                                    clientInfo.client.contactNumber
                                  }
                                  id="phone"
                                  name="phone"
                                  required="required"
                                  type="tel"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="mt-4">
                            <Col md="6">
                              <Button color="info" type="button">
                                Edit
                              </Button>
                              {/* <Button className="btn-simple ml-1" color="info" type="button">
                                Cancel
                              </Button> */}
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                      {/* <TabPane tabId="profile2">
                        <header>
                          <h2 className="text-uppercase">Billing method</h2>
                        </header>
                        <hr className="line-info" />
                        <br />
                        <Table className="align-items-center">
                          <thead>
                            <tr>
                              <th scope="col">Card Type</th>
                              <th scope="col">Card Number</th>
                              <th scope="col">Payment Method</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <img
                                  alt="..."
                                  className="avatar"
                                  src={require('../../assets/img/visas.png')}
                                />
                              </th>
                              <td>
                                <span className="d-block">•••• •••• •••• 8372</span>
                                <small className="text-muted">Exp: 06/22</small>
                              </td>
                              <td className="text-center">
                                <FormGroup check className="form-check-radio">
                                  <Label check>
                                    <Input
                                      defaultChecked
                                      defaultValue="option2"
                                      id="Radios"
                                      name="exampleRadios"
                                      type="radio"
                                    />
                                    <span className="form-check-sign" />
                                  </Label>
                                </FormGroup>
                              </td>
                              <td>
                                <Button
                                  className="btn-simple"
                                  color="danger"
                                  size="sm"
                                  type="button"
                                >
                                  <i className="tim-icons icon-simple-remove" /> Remove card
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                <img
                                  alt="..."
                                  className="avatar"
                                  src={require('../../assets/img/mastercard.png')}
                                />
                              </th>
                              <td>
                                <span className="d-block">•••• •••• •••• 1225</span>
                                <small className="text-muted">Exp: 07/21</small>
                              </td>
                              <td className="text-center">
                                <FormGroup check className="form-check-radio">
                                  <Label check>
                                    <Input
                                      defaultValue="option1"
                                      id="Radios"
                                      name="exampleRadios"
                                      type="radio"
                                    />
                                    <span className="form-check-sign" />
                                  </Label>
                                </FormGroup>
                              </td>
                              <td>
                                <Button
                                  className="btn-simple"
                                  color="danger"
                                  size="sm"
                                  type="button"
                                >
                                  <i className="tim-icons icon-simple-remove" /> Remove card
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <Button color="info" size="sm" type="button">
                          <i className="tim-icons icon-simple-add" /> Add card
                        </Button>
                      </TabPane> */}
                      <TabPane tabId="profile3">
                        <div className="g-pos-rel h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-30--md">
                          <header>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                              Security Questions
                            </h2>
                          </header>
                          <hr className="line-info" />
                          <Form>
                            <Row>
                              <Col md="6">
                                <label>Security Question</label>
                                <FormGroup>
                                  <Select
                                    className="react-select react-select-info"
                                    classNamePrefix="react-select"
                                    placeholder="Question"
                                    value={this.state.question}
                                    onChange={(value) => this.setState({ question: value })}
                                    options={[
                                      {
                                        value: '',
                                        label: 'Your Question',
                                        isDisabled: true,
                                      },
                                      {
                                        value: 'Which town did you meet your spouse',
                                        label: 'Which town did you meet your spouse',
                                      },
                                      {
                                        value: 'Which town were you born?',
                                        label: 'Which town were you born?',
                                      },
                                    ]}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <label>Your Answer</label>
                                <FormGroup>
                                  <Input placeholder="Enter your answer" type="text" />
                                </FormGroup>
                              </Col>
                            </Row>
                            <br />
                            <br />
                            <header>
                              <h2 className="text-uppercase">Security Settings</h2>
                            </header>
                            <hr className="line-info" />
                            <FormGroup className="d-flex align-items-center justify-content-between">
                              <span>Notify me via email when logging in</span>
                              <Switch offColor="" onColor="" />
                            </FormGroup>
                            <FormGroup className="d-flex align-items-center justify-content-between">
                              <span>Send SMS confirmation for all online payments</span>
                              <Switch offColor="" onColor="" />
                            </FormGroup>

                            <Row className="mt-5 justify-content-end">
                              <Col className="ml-auto" md="4">
                                <Button
                                  className="btn-simple mr-1"
                                  color="info"
                                  size="sm"
                                  type="button"
                                >
                                  Cancel
                                </Button>
                                <Button color="info" size="sm" type="button">
                                  Save Changes
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>

                      <TabPane tabId="profile5">
                        <header>
                          <h2 className="text-uppercase">Unpaid Invoices</h2>
                        </header>
                        <hr className="line-info" />
                        <br />
                        {clientInfo &&
                          clientInfo.unpaidInvoices &&
                          clientInfo.unpaidInvoices.length > 0 &&
                          clientInfo.unpaidInvoices.map((invoice) => <div>{invoice.status}</div>)}
                        {clientInfo &&
                        clientInfo.unpaidInvoices &&
                        clientInfo.unpaidInvoices.length > 0 ? (
                          clientInfo.unpaidInvoices.map((invoice) => (
                            <Table className="align-items-center">
                              <thead>
                                <tr>
                                  <th scope="col">Description</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">
                                    <span>{invoice.description}</span>
                                  </th>
                                  <td>
                                    {/* <span className="d-block">KSH {invoice.amount}</span> */}
                                    <span className="d-block">FREE</span>
                                    <small className="text-muted">
                                      Created:{' '}
                                      <Moment date={invoice.createdAt} format="DD/MM/YYYY" />
                                    </small>
                                  </td>
                                  <td>
                                    {/* <PaymentButton
                                      amount={invoice.amount}
                                      name={`${clientInfo.user.firstName} ${clientInfo.user.lastName}`}
                                      phone={clientInfo.user.phoneNumber}
                                      email={clientInfo.user.email}
                                      confirmPayment={this.props.confirmPayment}
                                      subscriptionId={invoice.subscriptionId}
                                    /> */}
                                    <Button onClick={(e) => this.confirmPayment(e, invoice.subscriptionId, 12)}>Pay</Button>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          ))
                        ) : (
                          <div className="text-center">You have no unpaid invoices</div>
                        )}
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default UserSettings;
