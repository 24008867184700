import React from 'react';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Media,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import ColorNavbar from '../common/ColorNavbar';

import DemoFooter from '../common/DemoFooter';
// custom previous button for the slick component
const PrevButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="primary"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="primary"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};

class BlogMobilize extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add('blog-post');
  }
  componentWillUnmount() {
    document.body.classList.remove('blog-post');
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header header-filter">
            <div
              className="page-header-image"
              data-parallax={true}
              style={{
                backgroundImage:
                  'url(https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80)',
              }}
            />
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h1 className="title">4 WAYS TO MOBILIZE YOUR TEAM AND DRIVE GROWTH </h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <p>
                    Imagine this scenario: You join your team’s Zoom meeting. After a few minutes of
                    friendly chit-chat, the conversation starts circling a vague objective that’s
                    never discussed in any meaningful way. Nothing gets decided, no one is assigned
                    ownership, and everyone closes out the Zoom window muttering about how much time
                    they just wasted. Does this sound familiar? A meeting like that stops any
                    efforts to get anywhere dead in its tracks. <br />
                    <br />
                    Though an obstacle like this may seem small, it can spell D-A-N-G-E-R.
                    <br />
                    As CEO, your job is to maintain the awareness and discipline to ensure these
                    challenges do not get out of hand and threaten to bring down your BIG dreams for
                    2022.
                  </p>
                  <br />
                  <br />
                  {/* <p className="blockquote">
                    “And thank you for turning my personal jean jacket into a couture piece.” <br />
                    <br />
                    <small>Kanye West, Producer.</small>
                  </p> */}
                  <h3 className="title">Here are 4 ways to mobilize your team to drive growth:</h3>
                  <br />
                  <p>
                    1.{' '}
                    <strong>
                      Reinforce your company’s core values to prevent the “Big Quit” from top talent
                    </strong>{' '}
                    Companies that undergo a thoughtful process to develop values they believe in
                    and bring them to life from the top down consistently are those that make an
                    extraordinary and lasting impact.
                    <br />
                    <br />
                    2.{' '}
                    <strong>Make sure you have the right team to get where you want to go</strong>
                    To Make BIG Happen, you need the right A-players by your side, the team that
                    will get you where you want to go. Our Organization of the Future Tool is a
                    disruptive way of looking at your business that can help you accelerate key
                    hires and upgrades now so you’re setting yourself up to reach your next big
                    goal.
                    <br />
                    <br />
                    3. <strong>Promote laser focus by running more effective meetings</strong> At
                    CEO Coaching International, we recommend making your meetings “rapid,” or RPID.
                    This four-part framework helps CEOs create a rigorous, clear, and regular
                    cadence for efficient communication that keeps all levels of the business
                    aligned toward Making BIG Happen.
                    <br />
                    <br />
                    4. <strong>Be flexible</strong> In the age of COVID-19, flexibility is a
                    buzzword we often hear as it pertains to employees’ expectations around their
                    working situation— flexibility to work remotely, flexibility to work compressed
                    weeks, sabbaticals, etc. As the leader, it is in your best interest to listen
                    and take action to build trust as the CEO.
                    <br />
                    <br />
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section section-blog-info">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <Row>
                    <Col md="10">
                      <div className="blog-tags">
                        Tags:  <Badge color="primary">Photography</Badge>
                        <Badge color="primary" className="ml-1">
                          Teamwork
                        </Badge>
                        <Badge color="primary" className="ml-1">
                          Tips
                        </Badge>
                      </div>
                    </Col>
                    <hr />
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          <DemoFooter />
        </div>
      </>
    );
  }
}

export default BlogMobilize;
