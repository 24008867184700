import React from 'react';
import PropTypes from 'prop-types';
// import PublicRoute from './components/common/PublicRoute';
import { ToastContainer } from 'react-toastify';

import store from './store/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AboutUs from './components/containers/AboutUs';
import Application from './components/containers/Application';
import LandingPage from './components/containers/LandingPage';
import Error404 from './components/containers/Error404';
import Error500 from './components/containers/Error500';
// import StaffLogin from './components/presentations/StaffLogin';
import StaffLoginContainer from './components/containers/StaffLogin';
import ClientLoginContainer from './components/containers/ClientLoginContainer';
import PasswordResetContainer from './components/containers/PasswordResetContainer';
import ProtectedRoute from './components/common/ProtectedRoute';
// import UserSettings from './components/presentations/UserSettings';
import UserSettingsContainer from './components/containers/UserSettingsContainer';
// import Quora from './components/presentations/Quora';
import QuoraContainer from './components/containers/QuoraContainer';
import SingleQuestionContainer from './components/containers/SingleQuestionContainer';
import Logout from './components/presentations/Logout';
import ContactUsContainer from './components/containers/ContactUsContainer';
import OurCoachesContainer from './components/containers/OurCoachesContainer';
// import BlogContainer from './components/containers/BlogContainer';
import BlogCeoBurnout from './components/presentations/Blog-ceo-burnout';
import BlogLeadershipChallenges from './components/presentations/Blog-leadership-challenges';
import BlogBooksCeosShouldRead from './components/presentations/Blog-ceos-should-read.jsx';
import BlogMobilize from './components/presentations/Blog-mobilize';
import PasswordResetContainerV2 from './components/containers/PasswordResetContainerV2';
import MeetingContainer from './components/containers/MeetingContainer';

const App = () => {
  return (
      <Provider store={store}>
        <BrowserRouter>
          <React.Fragment>
            <ToastContainer autoClose={false} />
            <Switch>
              <Route path="/membership" render={(props) => <AboutUs {...props} />} />
              <ProtectedRoute path="/meeting" component={MeetingContainer} />

              <Route path="/apply" render={(props) => <Application {...props} />} />
              <Route path="/" exact render={(props) => <LandingPage {...props} />} />
              <ProtectedRoute exact path="/quora" component={QuoraContainer} />
              <ProtectedRoute path="/quora/:id" component={SingleQuestionContainer} />

              {/* Blog routes */}
              <Route path="/blog/mobilize-team" render={(props) => <BlogMobilize {...props} />} />
              <Route
                path="/blog/ceo-burnout-strategies"
                render={(props) => <BlogCeoBurnout {...props} />}
              />
              <Route
                path="/blog/leadership-challenges"
                render={(props) => <BlogLeadershipChallenges {...props} />}
              />
              <Route
                path="/blog/books-ceos-should-read"
                render={(props) => <BlogBooksCeosShouldRead {...props} />}
              />

              <Route path="/stafflogin" render={(props) => <StaffLoginContainer {...props} />} />
              <Route path="/contact-us" render={(props) => <ContactUsContainer {...props} />} />

              <Route path="/login" render={(props) => <ClientLoginContainer {...props} />} />
              <Route path="/our-coaches" render={(props) => <OurCoachesContainer {...props} />} />
              <Route
                path="/forgot-password"
                render={(props) => <PasswordResetContainer {...props} />}
              />
              <Route path="/logout/" exact component={Logout} />
              {/* <Route render={(props) => <Error404 {...props} />} /> */}
              <Route path="/500-error" render={(props) => <Error500 {...props} />} />
              <ProtectedRoute exact path="/dashboard" component={UserSettingsContainer} />

              {/* PasswordResetV2 */}
              <Route path="/auth/reset-password-v2/:token" render={(props) => <PasswordResetContainerV2 {...props} />} />
            </Switch>
          </React.Fragment>
        </BrowserRouter>
      </Provider>
  );
};

Provider.propTypes = {
  store: PropTypes.shape().isRequired,
};

export default App;
