import { connect } from 'react-redux';
import { getUserInfo } from '../../actions/clientActions';
import Quora from '../presentations/Quora';
import { getQuestionsList, getSingleQuestion, postNewQuestion } from '../../actions/qaActions';
import { setPagination } from '../../actions/paginatorActions';

const queryCreatorHelper = (page, limit, filterObject = {}) => {
  const {} = filterObject;
  let query = `page=${page}&limit=${limit}`;
  return query;
};

const mapStateToProps = (state) => ({
  isLoadingReducer: state.isLoadingReducer,
  qaReducer: state.qaReducer,
  clientInfo: state.client.clientInfo,
});

const QuoraContainer = connect(mapStateToProps, {
  getUserInfo,
  getQuestionsList,
  getSingleQuestion,
  queryCreatorHelper,
  setPagination,
  postNewQuestion,
})(Quora);

export default QuoraContainer;
