import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink, Col } from 'reactstrap';
import { setPageAndLimit } from '../actions/paginatorActions';

interface TablePaginationProps {
  count: number;
  countCaption: string;
  pageSetter: any;
  queryCreatorHelper: any;
}

const TablePagination = ({
  count,
  pageSetter,
  queryCreatorHelper,
}: TablePaginationProps) => {
  const limitHandler = (limit) => {
    if (limit >= 100) return 100;
    else if (limit >= 50) return 50;
    else if (limit >= 20) return 20;
    else if (limit >= 10) return 10;
    else return 5;
  };
  let limit = limitHandler(Math.floor(window.innerHeight / 55));
  // let limit = 6;
  const page = useSelector((state) => state['paginator'].page);
  const query = useSelector((state) => state['paginator'].query);
  // const appConfig = useSelector((state) => state['app']);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageAndLimit(1, limit, query));
  }, []);

  const showNewPage = (newPage) => {
     if (window.location.pathname.includes('/quora')) {
      dispatch(setPageAndLimit(newPage, limit, query));
     
      pageSetter({ page: newPage, limit, query });
    } else {
      dispatch(setPageAndLimit(newPage, limit, query));
      pageSetter(queryCreatorHelper(newPage, limit, query));
    }
  };

  // handling page-navigation
  const handleClick = (event) => {
    event.persist();
    let newPage = Number(event.target.id);
    console.log(newPage, limit, query);
    dispatch(setPageAndLimit(newPage, limit, query));
    showNewPage(newPage);
  };

  const prevPage = (page) => {
    let newPage = page - 1;
    dispatch(setPageAndLimit(newPage, limit, query));
    showNewPage(newPage);
  };

  const nextPage = (page) => {
    let newPage = page + 1;
    dispatch(setPageAndLimit(newPage, limit, query));
    showNewPage(newPage);
  };
  // end of handling pg-navigation

  // Logic for displaying page numbers
  let pageNumbers: any = [];
  let totalPages: number = Math.ceil(count / limit);

  // Logic for rendering page numbers
  if (totalPages > 5) {
    if (page < 4) {
      for (let i: number = 1; i < 6; i++) {
        pageNumbers.push(i);
      }
    } else if (page > totalPages - 3) {
      for (let i = totalPages - 4; i < totalPages + 1; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = page - 2; i < page + 3; i++) {
        pageNumbers.push(i);
      }
    }
  } else {
    for (let i = 1; i < totalPages + 1; i++) {
      pageNumbers.push(i);
    }
  }
  // end of pageNumberDisplayLogic
  return (
    <>
      <Col className="mr-auto ml-auto" lg="3" md="1">
        <div className="pagination-container justify-content-center ">
          <br /> <br /> <br />
          <Pagination
            className="pagination pagination-primary mr-auto ml-auto"
            listClassName="pagination-primary"
          >
            {pageNumbers.length > 1 ? (
              <PaginationItem>
                <PaginationLink
                  aria-label="Next"
                  href="#pablo"
                  style={{ color: 'white' }}
                  onClick={() => {
                    if (page > 1) {
                      prevPage(page);
                    }
                  }}
                >
                  <span aria-hidden={true}>
                    <i aria-hidden={true} className="fa fa-angle-double-left" />
                  </span>
                </PaginationLink>
              </PaginationItem>
            ) : null}
            {pageNumbers.map((number) => (
              <PaginationItem key={number} className={page === number ? 'active' : ''}>
                <PaginationLink
                  href="#pablo"
                  id={number}
                  style={{ color: 'white' }}
                  onClick={handleClick}
                >
                  {number}
                </PaginationLink>
              </PaginationItem>
            ))}
            {pageNumbers.length > 1 ? (
              <PaginationItem>
                <PaginationLink
                  aria-label="Next"
                  href="#pablo"
                  style={{ color: 'white' }}
                  onClick={() => {
                    if (page < totalPages) {
                      nextPage(page);
                    }
                  }}
                >
                  <span aria-hidden={true}>
                    <i aria-hidden={true} className="fa fa-angle-double-right" />
                  </span>
                </PaginationLink>
              </PaginationItem>
            ) : null}
          </Pagination>
        </div>
      </Col>
    </>
  );
};

export default TablePagination;
