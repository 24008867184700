import { toast } from 'react-toastify';

const exceptionHandler = (ex) => {
  if (!ex.request) return null;
  const { status } = ex.request;
  if (ex.response && status >= 400 && status < 500) {
    const { data } = ex.response;
    return toast.error((typeof data.message) == 'object' ? data.message[0] : data.message);
  }
  return toast.error('An unexpected error occurred');
};

export default exceptionHandler;
