import actionTypes from '../constants/qa.constants';

const initialState = {
  qa: {},
};

const qaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_QUESTIONS_SUCCESS:
      return { ...state, qa: action.user.data };
    case actionTypes.FETCH_QUESTIONS_FAILURE:
      return { ...state };
    case actionTypes.FETCH_SINGLE_QUESTION_SUCCESS:
      return { ...state, qa: action.question.data };
    case actionTypes.FETCH_SINGLE_QUESTION_FAILURE:
      return { ...state };
    case actionTypes.POST_ANSWER_SUCCESS:
      return { ...state };
    case actionTypes.POST_ANSWER_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export default qaReducer;
