import { toast } from 'react-toastify';
// import exceptionHandler from '../utils/exceptionHandler';
import http from '../shared/httpService';
import contentLoading from './loading.action';
import actionTypes from '../constants/application.constants';
import exceptionHandler from '../shared/exeptionHandler';
import { setToken, removeToken } from '../shared/authService';

const redirect = (redirectUrl) => {
  window.location = redirectUrl;
};

export const postStaffLoginSuccess = (staff) => ({
  type: actionTypes.POST_STAFF_LOGIN_SUCCESS,
  staff,
});

export const loginError = () => ({
  type: actionTypes.LOGIN_FAILURE,
});

export const postStaffLoginError = () => ({
  type: actionTypes.POST_STAFF_LOGIN_FAILURE,
});

export const login = (data: any, props: any) => {
  return async (dispatch) => {
    if (!navigator.onLine) {
      return toast.error('Please check your internet connection');
    }
    dispatch(contentLoading);
    try {
      const staff = await http.post('/auth/signin', data);
      dispatch(postStaffLoginSuccess(staff.data.user));
      setToken(staff.data.data.token);
      const { location } = props;
      const { state } = location;

      const path = state ? state.from.pathname : '/';
      //   @ts-ignore
      toast.success(staff.data.message);
      // return 'message';
      return redirect(path);
    } catch (err) {
      dispatch(postStaffLoginError());
      return exceptionHandler(err);
    }
  };
};

export const signup = (userObj) => {
  return async (dispatch) => {
    if (!navigator.onLine) {
      return toast.error('Please check your internet connection');
    }
    dispatch(contentLoading());
    try {
      const { data } = await http.post(`/auth/signup`, userObj);
      const { user } = data;
      const { token } = user;
      setToken(token);
      return redirect('/');
    } catch (ex) {
      return exceptionHandler(ex);
    } finally {
      dispatch(loginError());
    }
  };
};

export const confirmAccount = (token) => {
  return async (dispatch) => {
    dispatch(contentLoading());
    try {
      const { data } = await http.patch(`/auth/verification/${token}`);
      const { message } = data;
      toast.success(message);
      return message;
    } catch (ex) {
      return exceptionHandler(ex);
    } finally {
      dispatch(loginError());
    }
  };
};

export const forgotPassword = (data) => {
  return async (dispatch) => {
    dispatch(contentLoading());
    try {
      const {
        data: { message },
      } = await http.post('/auth/forgot-password', data);
      toast.success(message);
      return message;
    } catch (ex) {
      return exceptionHandler(ex);
    } finally {
      dispatch(loginError());
    }
  };
};

// reset password
export const resetPassword = (data, props) => {
  return async (dispatch) => {
    dispatch(contentLoading());
    try {
      const {
        data: { message },
      } = await http.post('/auth/reset-password', data);
      toast.success(message);
      const { location } = props;
      const { state } = location;

      const path = state ? state.from.pathname : '/';

      return redirect(path);
    } catch (ex) {
      return exceptionHandler(ex);
    } finally {
      dispatch(loginError());
    }
  };
}

export const resetPasswordV2 = (data, props) => {
  return async (dispatch) => {
    dispatch(contentLoading());
    try {
      const {
        data: { message },
      } = await http.post('/auth/reset-password-v2', data);
      toast.success(message);
      const { location } = props;
      const { state } = location;

      const path = state ? state.from.pathname : '/login';

      return redirect(path);
    } catch (ex) {
      return exceptionHandler(ex);
    } finally {
      dispatch(loginError());
    }
  };
}

// verifyOtp
export const verifyOtp = (data) => {
  return async (dispatch) => {
    dispatch(contentLoading());
    try {
      const {
        data: { message },
      } = await http.post('/otp/verify', data);
      toast.success(message);
      return message;
    } catch (ex) {
      return exceptionHandler(ex);
    } finally {
      dispatch(loginError());
    }
  };
};

export const logout = () => {
  removeToken();
  // @ts-ignore
  window.location = '/login';
};

export const socialLogin = () => {
  const socialLoginToken = window.location.href.split('?')[1].split('#')[0];
  if (socialLoginToken) {
    setToken(socialLoginToken);
    // @ts-ignore
    window.location = '/';
  }
};

// export default {
//   login,
//   signup,
//   confirmAccount,
//   logout,
//   socialLogin,
//   forgotPassword,
// };
