import { connect } from 'react-redux';
import { postContactUs } from '../../actions/adminActions';
import ContactUs from '../presentations/ContactUs';

const mapStateToProps = ({isLoadingReducer}) => {
    return {
        isLoadingReducer,
    };
};

const ContactUsContainer = connect(mapStateToProps, {
  postContactUs,
})(ContactUs);

export default ContactUsContainer;