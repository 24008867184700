import { Avatar } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import NavLink from 'reactstrap/lib/NavLink';

class ClientNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarColor: 'navbar-transparent',
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.changeNavbarColor);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.changeNavbarColor);
  }
  changeNavbarColor = () => {
    if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
      this.setState({
        navbarColor: 'bg-info',
      });
    } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      this.setState({
        navbarColor: 'navbar-transparent',
      });
    }
  };
  render() {
    return (
      <>
        <Navbar className={'fixed-top ' + this.state.navbarColor} expand="lg">
          <Container>
            <div className="navbar-translate">
              <NavbarBrand to="/dashboard" tag={Link} id="tooltip6619950104">
                <span>Powervel•</span>
              </NavbarBrand>
              <UncontrolledTooltip delay={0} target="tooltip6619950104">
                Making leaders powerful
              </UncontrolledTooltip>
              <button className="navbar-toggler" id="navigation">
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <UncontrolledCollapse navbar toggler="#navigation">
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Powervel•
                    </a>
                  </Col>
                  <Col className="collapse-close text-right" xs="6">
                    <button className="navbar-toggler" id="navigation">
                      <i className="tim-icons icon-simple-remove" />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <i aria-hidden={true} className="tim-icons icon-email-85" />
                  <NavLink href="/quora">Quora</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/meeting">
                    <i aria-hidden={true} className="tim-icons icon-tv-2" />
                    Join a Meeting
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle caret color="default" nav>
                    {/* <i
                      aria-hidden={true}
                      className="tim-icons icon-book-bookmark"
                    /> */}
                    <Avatar sx={{ width: 24, height: 24 }} />
                    <p></p>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem to="/dashboard" tag={Link}>
                      <i className="tim-icons icon-lock-circle" />
                      Account Settings
                    </DropdownItem>
                    <DropdownItem to='/logout' tag={Link}>
                      <i  className="tim-icons icon-square-pin" />
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default ClientNavbar;
