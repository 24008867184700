import { SET_PAGE_AND_LIMIT } from './../actions/paginatorActions';

const initialState = {
    page: 1,
    limit: 10,
    query: ''
};

const tablePaginator = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_PAGE_AND_LIMIT:
            if (action.error) return state;
            return {
                ...state,
                page: action.payload.page,
                limit: action.payload.limit,
                query: action.payload.query
            }
        default:
            return state;
    }
};

export default tablePaginator;
