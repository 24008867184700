import React from 'react';

// reactstrap components
import { Button, Card, CardHeader, Collapse, Container, Row, Col } from 'reactstrap';

class CandidateRequirements extends React.Component {
  state = {
    collapse: 1,
  };
  openCollapse = (collapse) => {
    this.setState({
      collapse: this.state.collapse === collapse ? -1 : collapse,
    });
  };
  render() {
    return (
      <>
        <div className="cd-section" id="accordion">
          <div className="accordion-1">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <h4 className="description">Candidate requirements</h4>
                  <div className="section-space" />
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="12">
                  <div className="accordion">
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Button className="w-100 text-left" color="link">
                            C-level executive, accomplished VP, or equivalent executive leadership
                            role within your organization{' '}
                          </Button>
                        </h5>
                      </CardHeader>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0 w-100 text-left ">
                          <Button className="w-100 text-left " color="link">
                            Established career with 15+ years of experience{' '}
                          </Button>
                        </h5>
                      </CardHeader>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Button className="w-100 text-left " color="link">
                            Commitment to driving impact and supporting others to change the face of
                            leadership{' '}
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 3}></Collapse>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default CandidateRequirements;
