import React, { Component } from 'react';
import { connect } from 'react-redux';

import ClientNavbar from './ClientNavbar';
import { logout } from '../../actions/authActions';

class ClientLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ClientNavbar logout={this.props.logout}/>
        <this.props.component {...this.props.props} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      token: state,
      logout: logout
    };
  };

export default connect(mapStateToProps, {})(ClientLayout);