import React from 'react';
import ReactDOM from 'react-dom';
// styles
import './assets/css/nucleo-icons.css';
import './assets/scss/blk-design-system-pro-react.scss?v1.0.0';
import './assets/demo/demo.css';
import './assets/demo/react-demo.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
