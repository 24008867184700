import { connect } from 'react-redux';
// import { getUserInfo } from '../../actions/clientActions';
import { getSingleQuestion, postAnswer, postComment } from '../../actions/qaActions';
import SingleQuestion from '../presentations/SingleQuestion';

const mapStateToProps = (state) => ({
  isLoadingReducer: state.isLoadingReducer,
  qaReducer: state.qaReducer,
});

const SingleQuestionContainer = connect(mapStateToProps, {
  getSingleQuestion,
  postAnswer,
  postComment
})(SingleQuestion);

export default SingleQuestionContainer;
