import React from 'react';
import { Button } from 'reactstrap';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

function PaymentButton({ amount, name, phone, email, confirmPayment, subscriptionId }) {
  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: amount,
    currency: 'KES',
    payment_options: 'mobilemoney',
    customer: {
      email,
      phonenumber: phone,
      name,
    },
    customizations: {
      title: 'Pay for your Subscription',
      description: 'Payment for Subscription',
      logo: 'https://res.cloudinary.com/plaitnum/image/upload/v1660131191/Watch_Out_ohozsm.png',
    },
  };
  const handleFlutterPayment = useFlutterwave(config);

  return (
    <>
      <Button
        className="btn-simple"
        color="danger"
        size="sm"
        type="button"
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
              console.log('i am heeeere', response);
              confirmPayment({
                amountPaid: response.amount,
                subscriptionId,
                transactionId: response.transaction_id,
                // txRef: response.tx_ref,
                // status: response.status,
              });
              closePaymentModal(); // this will close the modal programmatically
            },
            onClose: () => {},
          });
        }}
      >
        <i className="tim-icons icon-tablet-2" /> Pay now
      </Button>
    </>
  );
}

export default PaymentButton;
