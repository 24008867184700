import { toast } from 'react-toastify';
// import exceptionHandler from '../utils/exceptionHandler';
import http from '../shared/httpService';
import contentLoading from './loading.action';
import actionTypes from '../constants/application.constants';
import exceptionHandler from '../shared/exeptionHandler';

export const postApplicationSuccess = (article) => ({
  type: actionTypes.POST_APPLICATION_SUCCESS,
  article,
});

export const postApplicationError = () => ({
  type: actionTypes.POST_APPLICATION_FAILURE,
});

export const postApplication = (data) => {
  return async (dispatch) => {
    dispatch(contentLoading);
    try {
      const application = await http.post('/application', data);
      dispatch(postApplicationSuccess(application));
      //   @ts-ignore
      toast.success(application.data.message);
      setTimeout(() => {
        // @ts-ignore
        window.location = '/';
      }, 5000);
      return application;
    } catch (err) {
      console.log('err', err);
      dispatch(postApplicationError());
      return exceptionHandler(err);
    }
  };
};
