import React from 'react';
// ReactJS plugin for a nice carousel
import Slick from 'react-slick';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Media,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import ColorNavbar from '../common/ColorNavbar';

import DemoFooter from '../common/DemoFooter';
// custom previous button for the slick component
const PrevButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="primary"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="primary"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};

let slickSettings = {
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  className: 'slider col-md-8 ml-auto mr-auto',
  dots: false,
  infinite: true,
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

class BlogCeoBurnout extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add('blog-post');
  }
  componentWillUnmount() {
    document.body.classList.remove('blog-post');
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header header-filter">
            <div
              className="page-header-image"
              data-parallax={true}
              style={{
                backgroundImage:
                  'url(https://images.unsplash.com/photo-1573167710701-35950a41e251?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80)',
              }}
            />
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h1 className="title">CEO BURNOUT: 4 STRATEGIES TO RECHARGE</h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h6 className="category">March 1, 2022</h6>
                  <h3 className="title">CEO BURNOUT: 4 STRATEGIES TO RECHARGE</h3>
                  <p>
                    When the pandemic hit in the spring of 2020, the best CEOs shifted into high
                    gear, pivoting their businesses, protecting their workforces, and scanning the
                    disrupted landscape for BIG opportunities. <br />
                    <br />
                    Two years later, many CEOs are still grinding that high gear. Not necessarily
                    because they have to, but because they’re stuck. All those late nights and
                    skipped weekends that were necessary during the pandemic pivot became a habit.
                  </p>
                  <br />
                  <br />

                  <br />
                  <p>
                    Unsurprisingly, reports of executive burnout are on the rise. One recent study
                    found that 37% of executives are working longer hours than they did before the
                    pandemic. More than 75% are experiencing mental and physical health concerns
                    related to their work.
                    <br />
                    <br />
                    Under normal circumstances, the job of a CEO is tough enough. But as we move
                    towards a post-pandemic world that continues to present new types of challenges,
                    longer hours and rising levels of executive stress just aren’t sustainable
                    trends. Your vision for this next phase of your company also has to include a
                    new vision for your own role, including strategies for battling executive
                    burnout. Try incorporating one or more of these four ideas into your workday so
                    that BIG stress doesn’t get between you and Making BIG Happen.
                    <br /> <br />
                    <h3 className="title">1. Take back your schedule.</h3>
                    <br /> <br />
                    With so many work-life boundaries blurring right now, the CEO has to be
                    intentional about setting and sticking to a healthy schedule. If you can’t block
                    off some time to unplug, exercise, eat well, and get a good night’s sleep, then
                    you’re not running a business. The business is running you.
                    <br /> <br />
                    Remember, being the boss doesn’t mean that you have to personally manage
                    everything that’s happening. You’re the poet, not the plumber. Set targets, put
                    measurable, actionable steps in place that will move the company toward those
                    targets, and delegate responsibility.
                    <br /> <br />
                    If you don’t feel like you can do that, then somewhere you’ve cut a corner.
                    Maybe your vision isn’t BIG enough to achieve meaningful growth. Maybe you
                    haven’t identified the best processes to drive KPIs.
                    <br /> <br />
                    Or, most likely of all, you haven’t hired top performers to whom you can
                    delegate key tasks. Upgrade your team and you’ll find a lot more white space on
                    your daily agenda that you can fill in with trips to the gym or 20 minutes of
                    meditation.
                    <br /> <br />
                    <h3 className="title">2. Go small.</h3>
                    <br /> <br />
                    If learning a musical instrument or training for an IRONMAN sounds like an
                    impossible time commitment, look for less demanding ways to achieve your
                    self-care goals. Need to exercise more? Get up an hour earlier and run or ride
                    your bike before work. Can’t stop micromanaging, even though you know your team
                    is the best? Work from home on Fridays, or pad your lunch hour with a walk
                    through the park to clear your head. Lost the 30,000 foot perspective on your
                    business? Devote one afternoon every week to reading, attending webinars, or
                    other learning activities.
                    <br /> <br />
                    It’s also important that your CEO care routine is really yours. Don’t try to
                    jump into the hot fitness movement or hobby of the week just because you see
                    other CEOs posting about it on social media. A demanding activity that you don’t
                    really enjoy is just going to lead to more burnout. If all you really need is a
                    good cup of coffee and an hour with the newspaper, embrace that small change.
                    <br /> <br />
                    <h3 className="title">3. Step down from your pedestal.</h3>
                    <br /> <br />
                    Many CEOs are so worried about looking strong that they can’t even be honest
                    with themselves about their vulnerabilities. They see executive stress as a
                    weakness that they can only overcome by working harder. And they think mental
                    health is touchy-feely nonsense that low achievers use as an excuse.
                    <br /> <br />
                    My message to those CEOs: get over yourself.
                    <br /> <br />
                    A successful business doesn’t need Superman, it needs a leader. Part of
                    leadership is setting a BIG vision and modeling the confidence you want your
                    whole team to feel as you march towards that goal. But leadership is also about
                    understanding your people as people. You’ll never be able to develop essential
                    EQ skills or conquer burnout if you can’t look yourself in the mirror and say,
                    “I need a break, and that’s OK.”
                    <br /> <br />
                    Allow yourself to be human, and you’ll be much more attuned to the self-care you
                    need to reduce burnout and be the high performer your company needs. Show that
                    same vulnerable, imperfect leader to your employees and customers, and they’re
                    going to connect to you and your company on a much more personal level. That
                    connection will be good for culture, good for business, and good for your
                    health.
                    <br /> <br />
                    <h3 className="title">4. Build out your support network.</h3>
                    <br /> <br />
                    Just as you acclimated too well to working overtime, you might also be too used
                    to working in your CEO silo. This year, make reestablishing person-to-person
                    connections a priority.
                    <br /> <br />
                    Start taking those lunch meetings with other CEOs so that you can see how
                    they’re handling their own work-life challenges. Get in touch with that old boss
                    who mentored your transition from management to CEO. Work with an executive
                    coach who can give you a new perspective on your business and help you focus
                    your time on the select tasks that only the CEO can accomplish.
                    <br /> <br />
                    Being the CEO can be lonely, especially when you’re under the kind of pressure
                    that we’ve all experienced since COVID-19. But no CEO accomplishes anything
                    meaningful all on their own. The people you can connect with during these
                    moments of transition are going to be critical to your business’ success and
                    your own battle against burnout. Be open with them, and yourself, about what
                    you’re thinking, feeling, and needing, personally and professionally. That
                    clarity will guide you away from burning out and back to Making BIG Happen.
                    <br /> <br />
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section section-blog-info">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <Row>
                    <Col md="10">
                      <div className="blog-tags">
                        Tags:  <Badge color="primary">Leadership</Badge>
                        <Badge color="primary" className="ml-1">
                          Inspiration
                        </Badge>
                        <Badge color="primary" className="ml-1">
                          Coaching
                        </Badge>
                      </div>
                    </Col>
                    <hr />
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          <DemoFooter />
        </div>
      </>
    );
  }
}

export default BlogCeoBurnout;
