const actionType = {
  POST_APPLICATION_SUCCESS: 'POST_APPLICATION_SUCCESS',
  POST_APPLICATION_FAILURE: 'POST_APPLICATION_FAILURE',
  POST_STAFF_LOGIN_SUCCESS: 'POST_STAFF_LOGIN_SUCCESS',
  POST_STAFF_LOGIN_FAILURE: 'POST_STAFF_LOGIN_FAILURE',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

};

export default actionType;
