import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
  CardHeader,
  CardImg,
  Input,
  CardFooter,
  CardTitle,
  CardBody,
} from 'reactstrap';
import classnames from 'classnames';

function ChangePasswordModal({ toggle, isOpen, handleChange, handleSubmitChangePassword }) {
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} modalClassName="modal-login">
        <Card className="card-login">
          <Form action="" className="form" method="">
            <CardHeader>
              <CardImg alt="..." src={require('../../assets/img/square-purple-1.png')} />
              <CardTitle tag="h10">Change</CardTitle>
              <br />
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggle}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
            </CardHeader>
            <CardBody>
              <InputGroup className="input-lg">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-lock-circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="oldPassword"
                  onChange={(e) => handleChange(e)}
                  placeholder="Old Password..."
                  type="password"
                />
              </InputGroup>
              <InputGroup className="input-lg">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-lock-circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="newPassword"
                  onChange={(e) => handleChange(e)}
                  placeholder="New password..."
                  type="password"
                />
              </InputGroup>
            </CardBody>
            <CardFooter className="text-center">
              <Button
                block
                className="btn-round"
                color="primary"
                href="#pablo"
                onClick={(e) => handleSubmitChangePassword(e)}
                size="lg"
              >
                Update
              </Button>
            </CardFooter>
          </Form>
        </Card>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
