import React from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormGroup,
  Modal,
  CardHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import ClientNavbar from '../common/ClientNavbar';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SearchIcon from '@mui/icons-material/Search';
import Loader from '../../shared/Loader';
import TablePagination from '../../shared/Pagination';
import history from '../../shared/history';
import LoadingIcons from 'react-loading-icons';
import { Editor } from 'draft-js';

class Quora extends React.Component {
  constructor(props) {
    super(props);
    let windowScrollTop;
    if (window.innerWidth >= 768) {
      windowScrollTop = window.pageYOffset / 3;
    } else {
      windowScrollTop = 0;
    }
    this.state = {
      transform: 'translate3d(0,' + windowScrollTop + 'px,0)',
      limit: 6,
      page: 1,
      question: '',
      newQuestion: '',
      newQuestionDescription: '',
      modalNewQuestion: false,
      submittingNewQuestion: false,
      value: '',
    };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add('blog-posts');
    if (window.innerWidth >= 768) {
      var windowScrollTop = window.pageYOffset / 3;
      this.setState({
        transform: 'translate3d(0,' + windowScrollTop + 'px,0)',
      });
      window.addEventListener('scroll', this.resetTransform);
    }
    const { getUserInfo, getQuestionsList } = this.props;
    getUserInfo();
    getQuestionsList({ limit: this.state.limit, page: this.state.page });
  }
  componentWillUnmount() {
    document.body.classList.remove('blog-posts');
    if (window.innerWidth >= 768) {
      window.removeEventListener('scroll', this.resetTransform);
    }
  }
  resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: 'translate3d(0,' + windowScrollTop + 'px,0)',
    });
  };

  onSearchFormChange = (e) => {
    const { getQuestionsList } = this.props;
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (value.length > 3) {
      getQuestionsList({
        limit: 10,
        page: 1,
        question: value,
      });
    }
  };

  searchQuestions = (e) => {
    e.preventDefault();
    const { getQuestionsList } = this.props;
    getQuestionsList({
      limit: 10,
      page: 1,
      question: this.state.question,
    });
  };

  toggleNewQuestionModal = () => {
    this.setState({
      modalNewQuestion: !this.state.modalNewQuestion,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitNewQuestion = async (e) => {
    this.setState({ submittingNewQuestion: true });
    e.preventDefault();
    const { postNewQuestion, clientInfo, getQuestionsList } = this.props;
    const createdQ = await postNewQuestion(
      this.state.newQuestion,
      this.state.newQuestionDescription,
      clientInfo.client.id
    );
    history.push(`/quora/${createdQ.data.id}`);
    window.location.reload();
    await getQuestionsList();
    this.setState({ submittingNewQuestion: false });
  };
  render() {
    const { clientInfo, isLoadingReducer, qaReducer, queryCreatorHelper, getQuestionsList } =
      this.props;
    const { loader: isLoading } = isLoadingReducer;

    return (
      <>
        <Modal isOpen={this.state.modalNewQuestion} toggle={this.toggleNewQuestionModal} size="lg">
          <Card className="card-login">
            <Form action="" className="form" method="">
              <CardHeader>
                <h3 className="title text-center">New Question</h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={this.toggleNewQuestionModal}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Input
                    defaultValue=""
                    value={this.state.newQuestion}
                    placeholder="Type the question here..."
                    onChange={(e) => this.onChange(e)}
                    type="text"
                    name="newQuestion"
                  />
                </FormGroup>
                <br /> <br />
                <FormGroup>
                  <div>
                    <FormGroup>
                      <Input
                        placeholder="Comment..."
                        rows="4"
                        type="textarea"
                        onChange={(e) => this.onChange(e)}
                        name="newQuestionDescription"
                        value={this.state.newQuestionDescription}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </CardBody>
              <CardFooter className="text-center">
                {this.state.submittingNewQuestion ? (
                  <Button block color="primary" disabled>
                    <LoadingIcons.Puff stroke="#98ff98" strokeOpacity={0.125} speed={0.75} />
                  </Button>
                ) : (
                  <Button
                    block
                    // className="btn-round"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => this.submitNewQuestion(e)}
                    // size="lg"
                  >
                    Submit
                  </Button>
                )}
              </CardFooter>
            </Form>
          </Card>
        </Modal>
        <ClientNavbar />
        {isLoading && <Loader />}
        <div className="wrapper" ref="wrapper">
          <div className="page-header">
            <Container>
              <Row className="align-items-center mt-md-5">
                <Col className="ml-auto mr-auto" lg="6" md="8">
                  <h2 className="title text-left">This is a place to gain and share knowledge.</h2>
                  <InputGroup
                    className={classnames({
                      'input-group-focus': this.state.focus,
                    })}
                  >
                    <Input
                      placeholder="Search questions"
                      type="text"
                      name="question"
                      onFocus={(e) => this.setState({ focus: true })}
                      onBlur={(e) => this.setState({ focus: false })}
                      onChange={(e) => this.onSearchFormChange(e)}
                      // onhange={(e) => queryCreatorHelper(1, 10, { question: e.target.value })}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <SearchIcon fontSize="3" onClick={(e) => this.searchQuestions(e)} />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>

                  {clientInfo &&
                  clientInfo.subscription &&
                  clientInfo.unpaidInvoices &&
                  clientInfo.subscription.isActive &&
                  clientInfo.unpaidInvoices.length == 0 ? (
                    <>
                      <Button
                        className="btn-simple btn-icon"
                        id="tooltip6619950114"
                        color="info"
                        href=""
                        onClick={this.toggleNewQuestionModal}
                      >
                        <i className="tim-icons icon-send" />
                      </Button>

                      <UncontrolledTooltip delay={0} target="tooltip6619950114">
                        Post a Question
                      </UncontrolledTooltip>
                    </>
                  ) : (
                    <>
                      <Button
                        className="btn-simple btn-icon"
                        id="tooltip6619950114"
                        color="info"
                        href=""
                        onClick={this.toggleNewQuestionModal}
                        disabled
                      >
                        <i className="tim-icons icon-send" />
                      </Button>

                      <UncontrolledTooltip delay={0} target="tooltip6619950114">
                        Post a Question
                      </UncontrolledTooltip>
                    </>
                  )}
                </Col>
                <Col className="ml-auto text-center" lg="4">
                  <div className="phone-container">
                    <img
                      alt="..."
                      className="phone"
                      src="https://res.cloudinary.com/plaitnum/image/upload/v1659876389/quora_hixroh.svg"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {clientInfo &&
          clientInfo.subscription &&
          clientInfo.unpaidInvoices &&
          clientInfo.subscription.isActive &&
          clientInfo.unpaidInvoices.length == 0 ? (
            <div className="main main-raised">
              <Container>
                <h2 className="title">Latest Questions</h2>
                <Row>
                  {qaReducer &&
                    qaReducer.qa.questions &&
                    qaReducer.qa.questions.map((question) => (
                      //   <div className="typography-line">
                      //   <span>Quote</span>
                      //   <blockquote>
                      //     <p className="blockquote blockquote-info">
                      //       <a href="#" onClick={(e) => history.push(`/quora/${question.id}`)}>
                      //         {question.question}
                      //       </a>

                      //       <p className="card-description">
                      //         {question.description ? `${question.description.slice(0, 70)} ...` : ''}
                      //       </p>
                      //       <br />
                      //       <small>
                      //         - {`${question.Client.User.firstName} ${question.Client.User.lastName}`}
                      //       </small>
                      //     </p>
                      //   </blockquote>
                      // </div>

                      // <Container>
                      //   <div id="typography">
                      //     <Row>
                      //       <Col md="12">
                      //         <div className="typography-line">
                      //           {/* <span>Warning Text</span> */}
                      //           <p className="text-warning">
                      //             <a
                      //               href="#"
                      //               onClick={(e) => history.push(`/quora/${question.id}`)}
                      //             >
                      //               {question.question}
                      //             </a>
                      //           </p>
                      //         </div>
                      //       </Col>
                      //     </Row>
                      //   </div>
                      // </Container>
                      <Col key={question.id} lg="4" md="2">
                        <Card className=" card-plain">
                          {/* <div className="card-image">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              <img
                                alt="..."
                                className="img rounded"
                                src={require('../../assets/img/noah-wetering.jpg')}
                              />
                            </a>
                          </div> */}
                          <CardBody>
                            {/* <h6 className="category text-info">Animals</h6> */}
                            <CardTitle tag="h4">
                              <a href="#" onClick={(e) => history.push(`/quora/${question.id}`)}>
                                {question.question}
                              </a>
                            </CardTitle>
                            <p className="card-description">
                              {question.description
                                ? `${question.description.slice(0, 70)} ...`
                                : ''}
                            </p>
                            <CardFooter>
                              <div className="author">
                                <img
                                  alt="..."
                                  className="avatar img-raised"
                                  src={
                                    question.Client.User.profilePicture
                                      ? question.Client.User.profilePicture
                                      : 'https://images.unsplash.com/photo-1634034379073-f689b460a3fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
                                  }
                                />
                                <span className="ml-1">{`${question.Client.User.firstName} ${question.Client.User.lastName}`}</span>
                              </div>

                              <div className="stats stats-right">
                                <ThumbUpIcon /> {question.upvotes}
                              </div>
                            </CardFooter>
                          </CardBody>
                        </Card>
                      </Col>

                      // <Col className="ml-auto mr-auto" md="5">
                      //   <Card className="card-plain blog-horizontal">
                      //     <Row>
                      //       <Col lg="12">
                      //         <CardBody>
                      //           <CardTitle tag="h3">
                      //             <a
                      //               href="#"
                      //               onClick={(e) => history.push(`/quora/${question.id}`)}
                      //             >
                      //               {question.question}
                      //             </a>
                      //           </CardTitle>
                      //           <p className="card-description">
                      //             {question.description
                      //               ? `${question.description.slice(0, 70)} ...`
                      //               : ''}{' '}
                      //             <a
                      //               href="#"
                      //               onClick={(e) => history.push(`/quora/${question.id}`)}
                      //             >
                      //               Read More
                      //             </a>
                      //           </p>
                      //           <div className="author">
                      //             <div className="text">
                      //               <span className="name">{`${question.Client.User.firstName} ${question.Client.User.lastName}`}</span>
                      //             </div>
                      //           </div>
                      //         </CardBody>
                      //       </Col>
                      //     </Row>
                      //   </Card>
                      // </Col>
                    ))}

                  <TablePagination
                    count={
                      qaReducer &&
                      qaReducer.qa &&
                      qaReducer.qa.pagination &&
                      qaReducer.qa.pagination.count
                    }
                    pageSetter={getQuestionsList}
                    queryCreatorHelper={queryCreatorHelper}
                  />
                </Row>
              </Container>
            </div>
          ) : (
            <div className="subscribe-line">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="8" xs="10">
                    <div className="text-center">
                      <h4 className="title">Your Subscription is not active</h4>
                      <p className="description">
                        Check if you have unpaid invoices in account settings.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Quora;
