import React from 'react';

// nodejs library that concatenates classes
import classnames from 'classnames';
// ReactJS plugin for a nice carousel
import Slick from 'react-slick';
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Badge,
  CardFooter,
} from 'reactstrap';

function Partners() {
  return (
    <>
      <div className="projects-3">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center mb-5" md="8">
              <h6 className="category text-muted">Our work</h6>
              <h2 className="title mt-0">Some of the things we thrive at</h2>
            </Col>
          </Row>
          <div className="space-50" />
          <Row>
            <Col md="6">
              <Card className="card-blog card-plain">
                <CardHeader>
                  <h2 className="title">Coaching</h2>
                  {/* <Badge color="danger" className="mr-1">
                        Methods
                      </Badge>
                      <Badge color="info">Purposes</Badge> */}
                </CardHeader>
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img rounded img-raised"
                      src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    />
                  </a>
                </div>
                <CardFooter className="text-left">
                  <div className="author">
                    <Button className="btn-round btn-simple" color="primary">
                      <i className="tim-icons icon-triangle-right-17" />
                      Meet our Coaches
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-blog card-plain">
                <CardHeader>
                  <h2 className="title">Growth</h2>
                  {/* <Badge color="success">Informations</Badge> */}
                </CardHeader>
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img rounded img-raised"
                      src="https://images.unsplash.com/photo-1543286386-713bdd548da4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    />
                  </a>
                </div>
                <CardFooter className="text-left">
                  <div className="author">
                    <Button className="btn-round btn-simple" color="success">
                      Find out more
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <div className="space-50" />
          <Row>
            <Col md="6">
              <Card className="card-blog card-plain">
                <CardHeader>
                  <h2 className="title">Innovation</h2>
                  {/* <Badge color="warning" className="mr-1">
                        Healthy
                      </Badge>
                      <Badge color="primary">Young</Badge> */}
                </CardHeader>
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img rounded img-raised"
                      src="https://images.unsplash.com/photo-1496065187959-7f07b8353c55?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    />
                  </a>
                </div>
                <CardFooter className="text-left">
                  <div className="author">
                    <Button className="btn-round btn-simple" color="warning">
                      Read more
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-blog card-plain">
                <CardHeader>
                  <h2 className="title">Strategy</h2>
                  {/* <Badge color="success" className="mr-1">
                        City
                      </Badge>
                      <Badge color="info" className="mr-1">
                        Urban
                      </Badge>
                      <Badge color="warning">Traffic</Badge> */}
                </CardHeader>
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img rounded img-raised"
                      src="https://images.unsplash.com/photo-1520881363902-a0ff4e722963?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    />
                  </a>
                </div>
                <CardFooter className="text-left">
                  <div className="author">
                    <Button className="btn-round btn-simple" color="info">
                      <i className="tim-icons icon-minimal-right" />
                      Read more
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Partners;
