import React, { Component } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import ColorNavbar from '../common/ColorNavbar';
import axios from 'axios';
import LoadingIcons from 'react-loading-icons';
import { ThirtyFpsOutlined } from '@mui/icons-material';
import DemoFooter from '../common/DemoFooter';

// core components

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      submittingContactUsForm: false,
      recapcha: '',
      firstNameValid: false,
      lastNameValid: false,
      emailValid: false,
      messageValid: false,
      recapchaValid: false,
      formValid: false,
      formErrors: {
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        recapcha: '',
      },
    };
  }

  onFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    this.validateFields(name, value);
  };

  onFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({ submittingContactUsForm: true });
    const { postContactUs } = this.props;

    await postContactUs({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      message: this.state.message,
      recapcha: this.state.recapcha,
    });
    this.setState({
      submittingContactUsForm: false,
      email: '',
      firstName: '',
      lastName: '',
      message: '',
    });
  };

  onRecapchaChange = (value) => {
    this.setState({ recapcha: value });
    this.validateFields('recapcha', value);
  };

  validateFields(field, value) {
    let firstNameValid = this.state.firstNameValid;
    let lastNameValid = this.state.lastNameValid;
    let emailValid = this.state.emailValid;
    let messageValid = this.state.messageValid;
    let fieldValidationErrors = this.state.formErrors;

    switch (field) {
      case 'firstName':
        firstNameValid = value.length >= 3;
        fieldValidationErrors.firstName = firstNameValid ? '' : ' is too short';
        break;
      case 'lastName':
        lastNameValid = value.length >= 3;
        fieldValidationErrors.lastName = lastNameValid ? '' : ' is too short';
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        break;
      case 'message':
        messageValid = value.length >= 3;
        fieldValidationErrors.message = messageValid ? '' : ' is too short';
        break;
      case 'recapcha':
        this.setState({ recapchaValid: value.length > 0 });
        fieldValidationErrors.recapcha = value.length > 0 ? '' : ' is required';
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        firstNameValid,
        lastNameValid,
        emailValid,
        messageValid,
      },
      this.validateForm()
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.firstNameValid &&
        this.state.lastNameValid &&
        this.state.emailValid &&
        this.state.messageValid &&
        this.state.recapchaValid,
    });
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="cd-section" id="contactus">
          <div
            className="contactus-1 section-image"
            style={{
              backgroundImage: 'url(' + require('../../assets/img/contact1.jpg') + ')',
            }}
          >
            <Container>
              <Row>
                <Col md="5">
                  <h2 className="title">Get in Touch</h2>
                  <h4 className="description">
                    You need more information? Check what other persons are saying about us.
                  </h4>

                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-mobile" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Give us a ring</h4>
                      <p className="description">
                        Vincent Muriuki <br />
                        +254-058-744-43 <br />
                        Mon - Fri, 8:00-18:00
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <Card className="card-contact card-raised">
                    <Form id="contact-form" method="post" role="form">
                      <CardHeader className="text-center">
                        <CardTitle tag="h4">Contact Us</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <label>First name</label>
                            <InputGroup
                              className={classnames({
                                'input-group-focus': this.state.firstNameContact1Focus,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="tim-icons icon-single-02" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                aria-label="First Name..."
                                placeholder="First Name..."
                                name="firstName"
                                value={this.state.firstName}
                                type="text"
                                onFocus={(e) =>
                                  this.setState({
                                    firstNameContact1Focus: true,
                                  })
                                }
                                onBlur={(e) =>
                                  this.setState({
                                    firstNameContact1Focus: false,
                                  })
                                }
                                onChange={(e) => this.onFormChange(e)}
                              />
                            </InputGroup>
                            <label style={{ color: '#ec250d' }}>
                              {!this.state.firstNameValid && this.state.formErrors.firstName}
                            </label>
                          </Col>
                          <Col className="pl-2" md="6">
                            <FormGroup>
                              <label>Last name</label>
                              <InputGroup
                                className={classnames({
                                  'input-group-focus': this.state.lastNameContact1Focus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="tim-icons icon-caps-small" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  aria-label="Last Name..."
                                  placeholder="Last Name..."
                                  value={this.state.lastName}
                                  type="text"
                                  onChange={(e) => this.onFormChange(e)}
                                  name="lastName"
                                  onFocus={(e) =>
                                    this.setState({
                                      lastNameContact1Focus: true,
                                    })
                                  }
                                  onBlur={(e) =>
                                    this.setState({
                                      lastNameContact1Focus: false,
                                    })
                                  }
                                />
                              </InputGroup>
                              <label style={{ color: '#ec250d' }}>
                                {!this.state.lastNameValid && this.state.formErrors.lastName}
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <label>Email address</label>
                          <InputGroup
                            className={classnames({
                              'input-group-focus': this.state.emailContact1Focus,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email Here..."
                              type="email"
                              name="email"
                              value={this.state.email}
                              onFocus={(e) => this.setState({ emailContact1Focus: true })}
                              onBlur={(e) => this.setState({ emailContact1Focus: false })}
                              onChange={(e) => this.onFormChange(e)}
                            />
                          </InputGroup>
                          <label style={{ color: '#ec250d' }}>
                            {!this.state.emailValid && this.state.formErrors.email}
                          </label>
                        </FormGroup>
                        <FormGroup>
                          <label>Your message</label>
                          <Input
                            id="message"
                            name="message"
                            value={this.state.message}
                            rows="6"
                            onChange={(e) => this.onFormChange(e)}
                            type="textarea"
                          />
                          <label style={{ color: '#ec250d' }}>
                            {!this.state.messageValid && this.state.formErrors.message}
                          </label>
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            {/* <FormGroup check> */}
                            <ReCAPTCHA
                              required
                              onChange={this.onRecapchaChange}
                              sitekey="6LetuVUfAAAAAL1Or3OY6193e9dmajXplQbZ6YN_"
                              size="normal"
                            />
                            <label style={{ color: '#ec250d' }}>
                              {!this.state.recapchaValid && this.state.formErrors.recapcha}
                            </label>
                            {/* </FormGroup> */}
                          </Col>
                          <Col md="6">
                            {this.state.submittingContactUsForm ? (
                              <Button className="btn-round pull-right" color="primary" disabled>
                                <LoadingIcons.TailSpin
                                  stroke="#98ff98"
                                  strokeOpacity={0.125}
                                  speed={0.75}
                                />
                              </Button>
                            ) : (
                              <Button
                                disabled={!this.state.formValid}
                                onClick={this.onFormSubmit}
                                className="btn-round pull-right"
                                color="primary"
                              >
                                Send
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>{' '}
        <DemoFooter />
      </>
    );
  }
}

export default ContactUs;
