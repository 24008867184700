import { apiPost } from './../shared/api-service';
import Cookie from 'universal-cookie'
import { errorHandler } from '../shared/helpers';
import contentLoading from './loading.action';
import http from '../shared/httpService';
import { toast } from 'react-toastify';
import exceptionHandler from '../shared/exeptionHandler';
import actionTypes from '../constants/auth.constants';

const cookie = new Cookie();

// define all actions here
export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_PENDING = 'CREATE_SESSION_PENDING';
export const API_AUTH_ERROR = 'API_AUTH_ERROR';

// signup user actions
export const SIGNUP_USER_PENDING = 'SIGNUP_USER_PENDING';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';




// define action methods here
export const sessionPost = (username, password) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_SESSION_PENDING,
    });
    http.post('/signin', { username, password })
      .then((res) => {
        try {
          cookie.set(
            'session',
            {
              username,
              token: res.token,
            },
            { path: '/' }
          );
        } catch (err) {}

        dispatch({
          type: CREATE_SESSION,
          payload: {
            ...res,
            username,
          },
        });
      })
      .catch((err) => {
        dispatch(errorHandler(err, API_AUTH_ERROR));
      });
  };
};

export const signupUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: SIGNUP_USER_PENDING,
    });
    http.post('/signup', data)
      .then((res) => {
        dispatch({
          type: SIGNUP_USER,
          payload: {
            ...res,
            // username,
          },
        });
      })
      .catch((err) => {
        dispatch(errorHandler(err, SIGNUP_USER_ERROR));
      });
  };
};

export const changeAuthPasswordSuccess = (data) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
    data,
  };
}

export const changeAuthPasswordFailure = (data) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAILURE
  }
}

export const changeAuthpassword = (data) => {
  return async(dispatch) => {
    dispatch(contentLoading);
    try {
      if (!navigator.onLine) {
        toast.error('Please check your internet connection');
      }
     const { data: response } = await http.post('/auth/reset-password/auth', data);
     toast.success(response.message);
     dispatch (changeAuthPasswordSuccess(response));
    } catch (ex) {
      dispatch(changeAuthPasswordFailure());
      return exceptionHandler(ex)
    }
  };
}