import React from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
import LoadingIcons from 'react-loading-icons';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from 'reactstrap';

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailConfirmed: false,
      otpConfirmed: false,
      newPasswordConfirmed: false,
      newPassword: '',
      newPassword1: '',
      newPasswordField: false,
      newPasswordValid: false,
      newPassword1Valid: false,
      submittingForm: false,
      submittingOtpForm: false,
      submittingPasswordForm: false,
      formErrors: {
        newPassword: '',
        newPassword1: '',
      },
      formValid: false,
    };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add('reset-page');
  }
  componentWillUnmount() {
    document.body.classList.remove('reset-page');
  }

  validateFields(field, value) {
    let newPasswordValid = this.state.newPasswordValid;
    let newPassword1Valid = this.state.newPassword1Valid;
    let fieldValidationErrors = this.state.formErrors;

    switch (field) {
      case 'newPassword':
        newPasswordValid = value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
        fieldValidationErrors.newPassword = newPasswordValid
          ? ''
          : 'Password must be at least 8 characters long and contain at least one number, one uppercase and one lowercase letter';
        break;
      case 'newPassword1':
        newPassword1Valid = value === this.state.newPassword;
        fieldValidationErrors.newPassword1 = newPassword1Valid ? '' : 'Passwords do not match';
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        newPasswordValid: newPasswordValid,
        newPassword1Valid: newPassword1Valid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.newPasswordValid && this.state.newPassword1Valid,
    });
  }

  submitForm = async (e) => {
    this.handleNormalChange('submittingForm', true);
    e.preventDefault();
    const { email } = this.state;
    const { forgotPassword } = this.props;
    const response = await forgotPassword({ email });
    this.handleNormalChange('submittingForm', false);
    // check if response containst string 'Successful'
    if (response.includes('Successful')) {
      this.setState({ emailConfirmed: true });
    }
  };

  handleNormalChange = (name, value) => {
    this.setState({ [name]: value });
    this.validateFields(name, value);
  };

  submitPaswordResetForm = async (e) => {
    e.preventDefault();
    this.handleNormalChange('submittingPasswordForm', true);
    const { email, newPassword } = this.state;
    const { resetPassword } = this.props;
    await resetPassword({ email, password: newPassword }, this.props);
    this.handleNormalChange('submittingPasswordForm', false);
  };
  onSubmitOtpForm = async (e) => {
    this.handleNormalChange('submittingOtpForm', true);
    e.preventDefault();
    const { email, otp } = this.state;
    const { verifyOtp } = this.props;
    const response = await verifyOtp({ otp });
    this.handleNormalChange('submittingOtpForm', false);
    // check if response containst string 'Verified'
    if (response.includes('Verified')) {
      this.setState({ otpConfirmed: true, newPasswordField: true });
    }
  };

  otpInputChange = (e) => {
    if (this.state.otp === 6) {
      this.onSubmitOtpForm(e);
    }
    const { value } = e.target;
    this.setState({ otp: value });
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      this.submitForm(event);
    }
  };

  render() {
    return (
      <>
        <div className="wrapper" ref="wrapper">
          <div className="page-header">
            <div className="squares square1" />
            <div className="squares square2" />
            <div className="squares square3" />
            <div className="squares square4" />
            <div className="squares square5" />
            <div className="squares square6" />
            <div className="page-header-image" />
            <Container>
              <Col className="mx-auto" lg="5" md="8">
                <Card className="card-login">
                  {!this.state.emailConfirmed ? (
                    <Form action="" className="form" method="">
                      <CardHeader>
                        <CardImg alt="..." src={require('../../assets/img/shape.png')} />
                        <CardTitle tag="h4">Reset Password</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <h4 className="description text-center">
                          Enter email address to reset password
                        </h4>
                        <InputGroup
                          className={classnames('input-lg', {
                            'input-group-focus': this.state.focus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control-lg"
                            placeholder="Your email"
                            type="email"
                            name="email"
                            onKeyDown={this.onKeyDown}
                            onChange={(e) => this.setState({ email: e.target.value })}
                            onFocus={(e) => this.setState({ focus: true })}
                            onBlur={(e) => this.setState({ focus: false })}
                          />
                        </InputGroup>
                        {this.state.submittingForm ? (
                          <Button block className="btn-round" color="warning" disabled size="sm">
                            <LoadingIcons.Puff
                              stroke="#98ff98"
                              strokeOpacity={0.125}
                              speed={0.75}
                            />
                          </Button>
                        ) : (
                          <Button
                            block
                            className="btn-round"
                            color="warning"
                            href="#pablo"
                            onClick={(e) => this.submitForm(e)}
                            size="lg"
                          >
                            Request Reset
                          </Button>
                        )}
                      </CardBody>
                      <CardFooter>
                        <div className="pull-left ml-3 mb-3">
                          <h6>
                            <a className="link footer-link" href="/login">
                              Back to Sign in
                            </a>
                          </h6>
                        </div>
                      </CardFooter>
                    </Form>
                  ) : this.state.emailConfirmed && !this.state.newPasswordField ? (
                    <Form action="" className="form" method="">
                      <CardBody>
                        <h4 className="description text-center">
                          Enter the OTP sent to your email
                        </h4>
                        <InputGroup
                          className={classnames('input-lg', {
                            'input-group-focus': this.state.focus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control-lg"
                            placeholder="OTP"
                            type="number"
                            min="0"
                            onChange={(e) => this.otpInputChange(e)}
                            onFocus={(e) => this.setState({ focus: true })}
                            onBlur={(e) => this.setState({ focus: false })}
                          />
                        </InputGroup>
                        {this.state.submittingOtpForm ? (
                          <Button block className="btn-round" color="warning" disabled size="sm">
                            <LoadingIcons.Puff
                              stroke="#98ff98"
                              strokeOpacity={0.125}
                              speed={0.75}
                            />
                          </Button>
                        ) : (
                          <Button
                            block
                            className="btn-round"
                            color="warning"
                            href="#pablo"
                            onClick={(e) => this.onSubmitOtpForm(e)}
                            size="lg"
                          >
                            Submit
                          </Button>
                        )}
                      </CardBody>
                      <CardFooter>
                        <div className="pull-left ml-3 mb-3">
                          <h6>
                            <a className="link footer-link" href="/login">
                              Back to Sign in
                            </a>
                          </h6>
                        </div>
                        <div className="pull-right ml-3 mb-3">
                          <h6>
                            <a
                              className="link footer-link"
                              href="#"
                              onClick={(e) => this.submitForm(e)}
                            >
                              Resend OTP
                            </a>
                          </h6>
                        </div>
                      </CardFooter>
                    </Form>
                  ) : this.state.otpConfirmed && this.state.newPasswordField ? (
                    <Form action="" className="form" method="">
                      <CardHeader>
                        <CardImg alt="..." src={require('../../assets/img/shape.png')} />
                        <CardTitle tag="h4">Set your new Password</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <InputGroup
                          className={classnames('input-lg', {
                            'input-group-focus': this.state.focus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control-lg"
                            placeholder="Your new password"
                            type="password"
                            name="newPassword"
                            onChange={(e) => this.handleNormalChange('newPassword', e.target.value)}
                            onFocus={(e) => this.handleNormalChange('focus', true)}
                            onBlur={(e) => this.handleNormalChange('focus', false)}
                          />
                          <label style={{ color: '#ec250d' }}>
                            {!this.state.newPasswordValid && this.state.formErrors.newPassword}
                          </label>
                        </InputGroup>

                        <InputGroup
                          className={classnames('input-lg', {
                            'input-group-focus': this.state.focus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control-lg"
                            placeholder="Confirm new password"
                            type="password"
                            name="newPassword1"
                            onChange={(e) =>
                              this.handleNormalChange('newPassword1', e.target.value)
                            }
                            onFocus={(e) => this.setState({ focus: true })}
                            onBlur={(e) => this.setState({ focus: false })}
                          />
                        </InputGroup>
                        <label style={{ color: '#ec250d' }}>
                          {!this.state.newPassword1Valid && this.state.formErrors.newPassword1}
                        </label>

                        {this.state.submittingPasswordForm ? (<Button block className="btn-round" color="warning" disabled size="sm">
                            <LoadingIcons.Puff
                              stroke="#98ff98"
                              strokeOpacity={0.125}
                              speed={0.75}
                            />
                          </Button>) : (<Button
                          block
                          className="btn-round"
                          color="warning"
                          disabled={!this.state.formValid}
                          onClick={(e) => this.submitPaswordResetForm(e)}
                          size="lg"
                        >
                          Submit
                        </Button>)}

                        
                      </CardBody>
                      <CardFooter>
                        <div className="pull-left ml-3 mb-3">
                          <h6>
                            <a className="link footer-link" href="/login">
                              Back to Sign in
                            </a>
                          </h6>
                        </div>
                      </CardFooter>
                    </Form>
                  ) : (
                    ''
                  )}
                </Card>
              </Col>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default PasswordReset;
