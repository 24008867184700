import { connect } from 'react-redux';
import UserSettings from '../presentations/UserSettings';
import { getUserInfo, confirmPayment } from '../../actions/clientActions';
import { changeAuthpassword } from '../../actions/sessionActions';

const mapStateToProps = (state) => ({
  clientInfo: state.client.clientInfo,
  isLoadingReducer: state.isLoadingReducer,
});

const UserSettingsContainer = connect(mapStateToProps, {
  getUserInfo,
  confirmPayment,
  changeAuthpassword,
})(UserSettings);

export default UserSettingsContainer;
