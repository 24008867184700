import { connect } from 'react-redux';
import { resetPasswordV2 } from '../../actions/authActions';
import PasswordResetV2 from '../presentations/PaswordResetV2';

const mapStateToProps = (state) => ({
  isLoadingReducer: state.isLoadingReducer
})

const PasswordResetContainerV2 = connect(mapStateToProps, {
  resetPasswordV2,
})(PasswordResetV2);

export default PasswordResetContainerV2;
