import { connect } from 'react-redux';
import { forgotPassword, verifyOtp, resetPassword } from '../../actions/authActions';
import PasswordReset from '../presentations/PasswordReset';

const mapStateToProps = (state) => ({
  isLoadingReducer: state.isLoadingReducer
})

const PasswordResetContainer = connect(mapStateToProps, {
  forgotPassword,
  verifyOtp,
  resetPassword,
})(PasswordReset);

export default PasswordResetContainer;
