/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  Container,
  Row,
  Nav,
  Navbar,
  tion,
  tionItem,
  tionLink,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
} from 'reactstrap';
import ColorNavbar from '../common/ColorNavbar';
import DemoFooter from '../common/DemoFooter';

// core components
const items = [
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Charlie Watson</h1>
          </Col>
          <Col md="4">
            <img alt="..." className="d-block" src={require('../../assets/img/charlie.jpg')} />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Position:</strong> Artist <br />
                <strong>Experience:</strong> 5 years
              </div>
              <div className="description">
                Artist is a term applied to a person who engages in an activity deemed to be an art.
                An artist also may be defined unofficially as "a person who expresses him- or
                herself through a medium". He is a descriptive term applied to a person who engages
                in an activity deemed to be an art. An artist also may be defined unofficially as "a
                person who expresses him- or herself through a medium".
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: '',
    caption: '',
    src: '0',
  },
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Quavo Austen</h1>
          </Col>
          <Col md="4">
            <img alt="..." className="d-block" src={require('../../assets/img/tom-klein.jpg')} />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Position:</strong> Actor <br />
                <strong>Experience:</strong> 1 year
              </div>
              <div className="description">
                Artist is a term applied to a person who engages in an activity deemed to be an art.
                An artist also may be defined unofficially as "a person who expresses him- or
                herself through a medium". He is a descriptive term applied to a person who engages
                in an activity deemed to be an art. An artist also may be defined unofficially as "a
                person who expresses him- or herself through a medium".
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: '',
    caption: '',
    src: '1',
  },
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Lucy</h1>
          </Col>
          <Col md="4">
            <img alt="..." className="d-block" src={require('../../assets/img/lucy.jpg')} />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Position:</strong> CEO <br />
                <strong>Experience:</strong> 7 years
              </div>
              <div className="description">
                Artist is a term applied to a person who engages in an activity deemed to be an art.
                An artist also may be defined unofficially as "a person who expresses him- or
                herself through a medium". He is a descriptive term applied to a person who engages
                in an activity deemed to be an art. An artist also may be defined unofficially as "a
                person who expresses him- or herself through a medium".
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round ml-1"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round ml-1"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: '',
    caption: '',
    src: '2',
  },
];

class Teams extends React.Component {
  state = {
    activeIndex: 0,
  };
  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="cd-section" id="teams">
          <div className="team-3">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">The Executive Team 3</h2>
                  <h4 className="description">Meet our coaches! 🚀</h4>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="5">
                  <Card className="card-profile" data-background="full">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised rounded"
                        src={require('../../assets/img/peter-ndegwa.jpeg')}
                      />
                    </a>
                    <div className="card-image">
                      <h4 className="title">Jane Doe</h4>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h3 className="job-title">Scrum Master</h3>
                      <Table className="tablesorter" responsive></Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="5">
                  <Card className="card-profile" data-background="full">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised rounded"
                        src={require('../../assets/img/tom-klein.jpg')}
                      />
                    </a>
                    <div className="card-image">
                      <h4 className="title">Tom Klein</h4>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h3 className="job-title">CTO</h3>
                      <Table className="tablesorter" responsive></Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="5">
                  <Card className="card-profile" data-background="full">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised rounded"
                        src={require('../../assets/img/kareya-saleh.jpg')}
                      />
                    </a>
                    <div className="card-image">
                      <h4 className="title">Hannah Khan</h4>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h3 className="job-title">Brand Strategist</h3>
                      <Table className="tablesorter" responsive></Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="5">
                  <Card className="card-profile" data-background="full">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised rounded"
                        src={require('../../assets/img/kareya-saleh.jpg')}
                      />
                    </a>
                    <div className="card-image">
                      <h4 className="title">Hannah Khan</h4>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h3 className="job-title">EXECUTIVE DESIGNER</h3>
                      <Table className="tablesorter" responsive></Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="5">
                  <Card className="card-profile" data-background="full">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised rounded"
                        src={require('../../assets/img/kareya-saleh.jpg')}
                      />
                    </a>
                    <div className="card-image">
                      <h4 className="title">Hannah Khan</h4>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h3 className="job-title">CCO</h3>
                      <Table className="tablesorter" responsive></Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="5">
                  <Card className="card-profile" data-background="full">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised rounded"
                        src={require('../../assets/img/kareya-saleh.jpg')}
                      />
                    </a>
                    <div className="card-image">
                      <h4 className="title">Hannah Khan</h4>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h3 className="job-title">CEO</h3>
                      <Table className="tablesorter" responsive></Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="projects-1">
            <div className="projects-1">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto text-center mb-5" lg="8">
                    <h2 className="title">Some of Our Projects</h2>
                  </Col>
                </Row>
              </Container>

              <Container>
                <Row>
                  <Col md="4">
                    <Card>
                      <CardHeader className="mt-2">
                        <div className="float-left">
                          <i className="tim-icons icon-tag d-inline text-info" />
                          <p className="d-inline ml-1">H-23</p>
                        </div>
                        <div className="float-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              aria-expanded={false}
                              caret
                              className="btn-icon"
                              color="link"
                              data-toggle="dropdown"
                              type="button"
                            >
                              <i className="tim-icons icon-settings-gear-63" />
                            </DropdownToggle>
                            <DropdownMenu right x-placement="bottom-end">
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Something else
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </CardHeader>
                      <CardBody className="text-center p-4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-fluid rounded-circle shadow"
                            src={require('../../assets/img/slack.png')}
                            style={{ width: '100px' }}
                          />
                        </a>
                        <CardTitle className="mb-0" tag="h4">
                          Slack
                        </CardTitle>
                        <p className="card-description">
                          We are happy to work at such a great project.
                        </p>
                        <CardTitle className="mt-4" tag="h5">
                          Members
                        </CardTitle>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip343637047"
                          >
                            <img alt="..." src={require('../../assets/img/james.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip343637047">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip346352270"
                          >
                            <img alt="..." src={require('../../assets/img/ryan.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip346352270">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip532183863"
                          >
                            <img alt="..." src={require('../../assets/img/lora.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip532183863">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip551759668"
                          >
                            <img alt="..." src={require('../../assets/img/mike.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip551759668">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                      <CardHeader className="mt-2">
                        <div className="float-left">
                          <i className="tim-icons icon-tag d-inline text-success" />
                          <p className="d-inline ml-1">F-43</p>
                        </div>
                        <div className="float-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              aria-expanded={false}
                              caret
                              className="btn-icon"
                              color="link"
                              data-toggle="dropdown"
                              type="button"
                            >
                              <i className="tim-icons icon-settings-gear-63" />
                            </DropdownToggle>
                            <DropdownMenu right x-placement="bottom-end">
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Something else
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </CardHeader>
                      <CardBody className="text-center p-4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-fluid rounded-circle shadow"
                            src={require('../../assets/img/spotify.jpeg')}
                            style={{ width: '100px' }}
                          />
                        </a>
                        <CardTitle className="mb-0" tag="h4">
                          Spotify
                        </CardTitle>
                        <p className="card-description">
                          We strive to embrace and drive change in our industry.
                        </p>
                        <CardTitle className="mt-4" tag="h5">
                          Members
                        </CardTitle>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip462477495"
                          >
                            <img alt="..." src={require('../../assets/img/james.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip462477495">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip300140885"
                          >
                            <img alt="..." src={require('../../assets/img/ryan.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip300140885">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip571610782"
                          >
                            <img alt="..." src={require('../../assets/img/lora.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip571610782">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip273194938"
                          >
                            <img alt="..." src={require('../../assets/img/mike.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip273194938">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                      <CardHeader className="mt-2">
                        <div className="float-left">
                          <i className="tim-icons icon-tag d-inline text-danger" />
                          <p className="d-inline ml-1">J-11</p>
                        </div>
                        <div className="float-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              aria-expanded={false}
                              caret
                              className="btn-icon"
                              color="link"
                              data-toggle="dropdown"
                              type="button"
                            >
                              <i className="tim-icons icon-settings-gear-63" />
                            </DropdownToggle>
                            <DropdownMenu right x-placement="bottom-end">
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Something else
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </CardHeader>
                      <CardBody className="text-center p-4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-fluid rounded-circle shadow"
                            src={require('../../assets/img/dribbble.png')}
                            style={{ width: '100px' }}
                          />
                        </a>
                        <CardTitle className="mb-0" tag="h4">
                          Dribbble
                        </CardTitle>
                        <p className="card-description">
                          The time has come to bring our plans and ideas to life.
                        </p>
                        <CardTitle className="mt-4" tag="h5">
                          Members
                        </CardTitle>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip76177270"
                          >
                            <img alt="..." src={require('../../assets/img/james.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip76177270">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip784059972"
                          >
                            <img alt="..." src={require('../../assets/img/ryan.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip784059972">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip876709653"
                          >
                            <img alt="..." src={require('../../assets/img/lora.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip876709653">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip101701505"
                          >
                            <img alt="..." src={require('../../assets/img/mike.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip101701505">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                      <CardHeader className="mt-2">
                        <div className="float-left">
                          <i className="tim-icons icon-tag d-inline text-warning" />
                          <p className="d-inline ml-1">A-11</p>
                        </div>
                        <div className="float-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              aria-expanded={false}
                              caret
                              className="btn-icon"
                              color="link"
                              data-toggle="dropdown"
                              type="button"
                            >
                              <i className="tim-icons icon-settings-gear-63" />
                            </DropdownToggle>
                            <DropdownMenu right x-placement="bottom-end">
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Something else
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </CardHeader>
                      <CardBody className="text-center p-4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-fluid rounded-circle shadow"
                            src={require('../../assets/img/Tim.png')}
                            style={{ width: '100px' }}
                          />
                        </a>
                        <CardTitle className="mb-0" tag="h4">
                          CreativeTim
                        </CardTitle>
                        <p className="card-description">
                          We are developing the best design projects.
                        </p>
                        <CardTitle className="mt-4" tag="h5">
                          Members
                        </CardTitle>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip380380732"
                          >
                            <img alt="..." src={require('../../assets/img/james.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip380380732">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip383345211"
                          >
                            <img alt="..." src={require('../../assets/img/ryan.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip383345211">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip364077597"
                          >
                            <img alt="..." src={require('../../assets/img/lora.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip364077597">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip988655272"
                          >
                            <img alt="..." src={require('../../assets/img/mike.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip988655272">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                      <CardHeader className="mt-2">
                        <div className="float-left">
                          <i className="tim-icons icon-tag d-inline text-primary" />
                          <p className="d-inline ml-1">A-11</p>
                        </div>
                        <div className="float-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              aria-expanded={false}
                              caret
                              className="btn-icon"
                              color="link"
                              data-toggle="dropdown"
                              type="button"
                            >
                              <i className="tim-icons icon-settings-gear-63" />
                            </DropdownToggle>
                            <DropdownMenu right x-placement="bottom-end">
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Something else
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </CardHeader>
                      <CardBody className="text-center p-4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-fluid rounded-circle shadow"
                            src={require('../../assets/img/dropbox.png')}
                            style={{ width: '100px' }}
                          />
                        </a>
                        <CardTitle className="mb-0" tag="h4">
                          DropBox
                        </CardTitle>
                        <p className="card-description">
                          It is important to save every project safely with our app.
                        </p>
                        <CardTitle className="mt-4" tag="h5">
                          Members
                        </CardTitle>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip989314295"
                          >
                            <img alt="..." src={require('../../assets/img/james.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip989314295">
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip230919456"
                          >
                            <img alt="..." src={require('../../assets/img/ryan.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip230919456">
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle mr-2"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip437311836"
                          >
                            <img alt="..." src={require('../../assets/img/lora.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip437311836">
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm rounded-circle"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip120355921"
                          >
                            <img alt="..." src={require('../../assets/img/mike.jpg')} />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip120355921">
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                      <CardHeader className="mt-2">
                        <div className="float-left">
                          <i className="tim-icons icon-tag d-inline" />
                          <p className="d-inline ml-1">E-28</p>
                        </div>
                        <div className="float-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              aria-expanded={false}
                              caret
                              className="btn-icon"
                              color="link"
                              data-toggle="dropdown"
                              type="button"
                            >
                              <i className="tim-icons icon-settings-gear-63" />
                            </DropdownToggle>
                            <DropdownMenu right x-placement="bottom-end">
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                Something else
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </CardHeader>
                      <CardBody className="text-center p-4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-fluid rounded-circle shadow"
                            src={require('../../assets/img/unass.jpg')}
                            style={{ width: '100px' }}
                          />
                        </a>
                        <CardTitle className="mb-0" tag="h4">
                          Unassigned
                        </CardTitle>
                        <p className="card-description">
                          Here you can add your description and bellow add your members.
                        </p>
                        <CardTitle className="mt-4" tag="h5">
                          Members
                        </CardTitle>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm rounded-circle"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            id="tooltip406568354"
                          >
                            <i className="tim-icons icon-lock-circle" />
                          </a>
                          <UncontrolledTooltip delay={0} target="tooltip406568354">
                            Add members
                          </UncontrolledTooltip>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>{' '}
        <DemoFooter />
      </>
    );
  }
}

export default Teams;
