import React from 'react';
// ReactJS plugin for a nice carousel
import Slick from 'react-slick';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  Container,
  Row,
  Col,
} from 'reactstrap';

class HomePageInsights extends React.Component {
  render() {
    return (
      <>
        <div className="section blogs-4">
          <Container fluid>
            <h1 className="title text-center">Get The Latest Insights</h1>
            <br />
            <Row>
              <Col lg="3">
                <Card className="card-blog card-background" data-animation="zooming">
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        'url(https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80)',
                    }}
                  />
                  <CardBody>
                    <div className="content-bottom">
                      <h6 className="card-category">Read more..</h6>
                      <a href="/blog/mobilize-team">
                        <CardTitle tag="h3">
                          4 Ways To Mobilize Your Team And Drive Growth{' '}
                        </CardTitle>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card className="card-blog card-background" data-animation="zooming">
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        'url(https://images.unsplash.com/photo-1573167710701-35950a41e251?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80)',
                    }}
                  />
                  <CardBody>
                    <div className="content-bottom">
                      <h6 className="card-category">Read more..</h6>
                      <a href="/blog/ceo-burnout-strategies">
                        <CardTitle tag="h3">CEO Burnout: 4 Strategies To Recharge</CardTitle>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card className="card-blog card-background" data-animation="zooming">
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        'url(https://images.unsplash.com/photo-1523875194681-bedd468c58bf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80)',
                    }}
                  />
                  <CardBody>
                    <div className="content-bottom">
                      <h6 className="card-category">Read more..</h6>
                      <a href="/blog/leadership-challenges">
                        <CardTitle tag="h3">The Top 10 Leadership Challenges</CardTitle>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card className="card-blog card-background" data-animation="zooming">
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        'url(https://images.unsplash.com/photo-1513001900722-370f803f498d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80)',
                    }}
                  />
                  <CardBody>
                    <div className="content-bottom">
                      <h6 className="card-category">Read more..</h6>
                      <a href="/blog/books-ceos-should-read">
                        <CardTitle tag="h3">The 6 Best Books Every CEO Should Read</CardTitle>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default HomePageInsights;
