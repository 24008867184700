import { connect } from 'react-redux';
import StaffSignin from '../presentations/StaffLogin';
import { login } from '../../actions/authActions';

const mapStateToProps = ({isLoadingReducer}) => {
    return {
        isLoadingReducer,
    };
};

const StaffLoginContainer = connect(mapStateToProps, { login })(StaffSignin);

export default StaffLoginContainer;
