import React from 'react';
// ReactJS plugin for a nice carousel
import Slick from 'react-slick';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Media,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import ColorNavbar from '../common/ColorNavbar';

import DemoFooter from '../common/DemoFooter';
// custom previous button for the slick component
const PrevButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="primary"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="primary"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};

let slickSettings = {
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  className: 'slider col-md-8 ml-auto mr-auto',
  dots: false,
  infinite: true,
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

class BlogBooksCeosShouldRead extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add('blog-post');
  }
  componentWillUnmount() {
    document.body.classList.remove('blog-post');
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header header-filter">
            <div
              className="page-header-image"
              data-parallax={true}
              style={{
                backgroundImage:
                  'url(https://images.unsplash.com/photo-1513001900722-370f803f498d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80)',
              }}
            />
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h1 className="title">THE 6 BEST BOOKS EVERY CEO SHOULD READ</h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h6 className="category">March 30, 2022</h6>
                  <h3 className="title">THE 6 BEST BOOKS EVERY CEO SHOULD READ</h3>
                  <p>
                    Hopefully now that life and business are getting back to normal, you’re settling
                    back into a routine that balances some well-deserved self care with a focus on
                    getting your teams to align on your company vision.
                    <br />
                    <br />
                    Learning in particular probably took on a very different meaning during the
                    pandemic, as you and your leadership team worked to make a safe and effective
                    pivot. Now, the 2.0 version of your business needs its CEO to start taking in
                    new information and synthesizing a wide variety of ideas into a bold new vision
                    for long-term success.
                    <br />
                    <br />
                    That’s where CEO books come in. Below, we provide our recommendation for the
                    best books on CEO leadership that can help make your learning time more
                    productive. Read through our list of the best books CEOs read so you can take
                    your company to new heights.
                    <br />
                    <br />
                    As a CEO, taking responsibility for both progress and setbacks is essential to
                    growing your company. The last thing your employees want is a leader who shifts
                    the blame to another team or employee instead of taking ownership]. After all,
                    you’re the CEO, which means you’re the person your company depends on.
                    <br />
                    <br />
                    In addition to taking extreme ownership, Willink laid out 12 key leadership
                    concepts that he learned as a Navy SEAL and has been able to apply to business.
                    Among these concepts are having a clear mission that you believe in, managing
                    your ego by continually learning and accepting feedback, letting teams work
                    together to accomplish a goal, and creating a soundproof plan. By applying these
                    concepts to your skillset, you’ll be able to lead with confidence.
                    <br />
                    <br />
                    <h3 className="title">
                      Drive: The Surprising Truth About What Motivates Us by Daniel H. Pink
                    </h3>
                    <br />
                    <br />
                    Another book CEOs read is by Daniel Pink, a multiple New York Times bestselling
                    author on business, creativity, work, and behavior. When most CEOs think about
                    how to motivate their teams, they weigh the carrot versus the stick. Pink
                    suggests a different approach: empower your team members so that they feel like
                    they’re in control of their daily responsibilities, their careers, and their
                    lives.
                    <br />
                    <br />
                    Rather than rewarding good behavior and punishing bad, Pink points out other
                    elements of motivation, including mastery, autonomy, and purpose. Focusing on
                    these factors can help boost a person’s innate drive to become more productive.
                    <br />
                    <br />
                    Pink finds that at our core, humans possess intrinsic motivation that makes us
                    eager to create and make the world a better place. As a CEO, however, it’s more
                    about biological and extrinsic motivations—it’s about finding ways to make
                    employees feel empowered at their job and truly believe in the work they
                    perform.
                    <br />
                    <br />
                    Remember not to solely focus on tapping into a worker’s intrinsic motivation by
                    giving them autonomy and purpose. Extrinsic motivation, such as rewards for
                    great performance, can still go a long way in creating a productive workforce.
                    If you’re looking for ways to motivate your staff, Pink’s CEO book provides the
                    answers and examples you need to light a fire under your employees.
                    <br />
                    <br />
                    <h3 className="title">
                      Measure What Matters: How Google, Bono, and the Gates Foundation Rock the
                      World with OKRs by John Doerr
                    </h3>
                    <br />
                    <br />
                    Venture capitalist John Doerr was an early investor in Google. His Objectives
                    and Key Results system (OKRs) helped young entrepreneurs Sergey Brin and Larry
                    Page identify the specific, measurable actions that would help their company hit
                    key targets and achieve BIG growth. If you’re looking for one of the best CEO
                    books of all time, this is it. In Measure What Matters, Doerr describes the four
                    elements of the OKR system.
                    <br />
                    <br />
                    First, you must focus on specific initiatives that can make a significant impact
                    on your business, while putting the less important ones on the back burner for a
                    later date. This way, leaders can zero in on the programs that can make a real
                    difference in their organization.
                    <br />
                    <br />
                    The second element of the OKR system is having the ability to align and connect.
                    This means being open and transparent about your goals. Everyone must share
                    their objectives and how they fit into the organization’s overall game plan,
                    which means they must work with other teams and communicate effectively.
                    <br />
                    <br />
                    The third element is that key objectives must be tracked. With objectives and
                    results that are driven by data, leaders can more efficiently measure progress
                    through periodic check-ins and continuous reassessment.
                    <br />
                    <br />
                    The final element to an efficient OKRs system is having the ability to motivate
                    your team to help them achieve more than they thought possible. To motivate
                    staff, it’s important to set attainable yet lofty goals that can help you take
                    your business to the next level.
                    <br />
                    <br />
                    <h3 className="title">
                      The 4 Disciplines of Execution: Achieving Your Wildly Important Goals by Chris
                      McChesney, Sean Covey, and Jim Huling
                    </h3>
                    <br />
                    <br />
                    The 4 Disciplines of Execution provides a framework for leaders to follow that
                    helps ensure the most important work gets completed. This CEO book is great for
                    leaders looking for ways to boost productivity and ensure goals are
                    accomplished.
                    <br />
                    <br />
                    “The 4DX” are:
                    <br />
                    <br />
                    <h3 className="title">- Focus on the wildly important</h3>: Pick a single goal,
                    dubbed a Wildly Important Goal (WIG), to focus on by brainstorming with your
                    team and creating a list of the top goals to go after. Then, agree on one goal
                    to tackle and make sure it’s attainable but challenging.
                    <br />
                    <br />
                    <h3 className="title">- Act on lead measures</h3>: Once you have your goal
                    defined, focus on activities that drive results, including lag measures, such as
                    the WIG you’re trying to achieve, and lead measures, which are sub-goals that
                    help you reach your WIG.
                    <br />
                    <br />
                    <h3 className="title">- Keep a compelling scoreboard</h3>: In order to stay
                    engaged, your team needs to see their progress. As you embark on your journey to
                    achieving your WIG, make sure to keep an updated scoreboard that tracks results
                    and notifies the team whether they’re on track. This way, they’ll stay motivated
                    and engaged throughout the entirety of the project.
                    <br />
                    <br />
                    <h3 className="title">- Create a cadence of accountability</h3>: To ensure you
                    accomplish your WIG, set up weekly meetings where team members gather and
                    discuss progress to hold individual team members accountable for their tasks,
                    solve problems, and ensure you’re on the right path.
                    <br />
                    <br />
                    Committing to these disciplines will inspire both CEOs and their key team
                    members to adapt their behaviors to get results that matter.
                    <br />
                    <br />
                    <br />
                    <br />
                    <h3 className="title">
                      Getting Naked: A Business Fable About Shedding The Three Fears That Sabotage
                      Client Loyalty by Patrick M. Lencioni
                    </h3>
                    Lencioni’s Leadership Fables use fictional storytelling to dramatize how
                    businesses can overcome significant obstacles. In “Getting Naked,” a boutique
                    consulting firm is acquired by a larger company and teaches its new owners
                    important lessons on generating customer loyalty.
                    <br />
                    <br />
                    <h3 className="title">
                      Good to Great: Why Some Companies Make the Leap and Others Don’t by Jim
                      Collins
                    </h3>
                    The book we’ve recommended the most at CEO Coaching International, and the one
                    that’s popped up the most in our blogs and podcasts, is Good to Great: Why Some
                    Companies Make the Leap and Others Don’t by Jim Collins. Collins and his
                    research team spent years studying good companies that became great and
                    identified some commonalities, including a laser focus on core competencies and
                    a commitment to hiring the best talent.
                    <br />
                    <br />
                    But ultimately, the leap Collins describes has to be taken by the CEO. It’s only
                    when the CEO learns to let go of ego, embrace humility, and build resilience
                    that a company of any size can learn to Make BIG Happen.
                    <br />
                    <br />
                    One concept Collins brings up in his book on CEO leadership is “First who, then
                    what.” To accomplish a goal, it’s important to start by asking, “Who?” not
                    “What?” Deciding “who” on your team is going to help you move forward rather
                    than backward before determining what you want to accomplish can ensure you’re
                    on the right path to success. Collins looked at employee compensation and found
                    that when it comes to compensating executives, it’s not how you compensate them,
                    but which executives deserve compensation in the first place.
                    <br />
                    <br />
                    There’s a reason Good to Great is on our list of the best CEO books of all time.
                    He outlines how you can create a system that gets the right people on your bus
                    and keeps them there, so you can have a clear path to success.
                    <br />
                    <br />
                  </p>
                  <br />
                  <br />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section section-blog-info">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <Row>
                    <Col md="10">
                      <div className="blog-tags">
                        Tags:  <Badge color="primary">Tips</Badge>
                        <Badge color="primary" className="ml-1">
                          Leadership
                        </Badge>
                        <Badge color="primary" className="ml-1">
                          Books
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <DemoFooter />
        </div>
      </>
    );
  }
}

export default BlogBooksCeosShouldRead;
