import React from 'react';

// reactstrap components
import { Button, Card, CardBody, Container, Row, Col, CardTitle } from 'reactstrap';

// core components
import ColorNavbar from '../common/ColorNavbar.jsx';
import DemoFooter from '../common/DemoFooter.jsx';
import Testimonials from './Testimonials.jsx';

const items = [
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Charlie Watson</h1>
          </Col>
          <Col md="4">
            <img alt="..." className="d-block" src={require('../../assets/img/charlie.jpg')} />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Position:</strong> Artist <br />
                <strong>Experience:</strong> 5 years
              </div>
              <div className="description">
                Artist is a term applied to a person who engages in an activity deemed to be an art.
                An artist also may be defined unofficially as "a person who expresses him- or
                herself through a medium". He is a descriptive term applied to a person who engages
                in an activity deemed to be an art. An artist also may be defined unofficially as "a
                person who expresses him- or herself through a medium".
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round mr-1"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round mr-1"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: '',
    caption: '',
    src: '0',
  },
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Quavo Austen</h1>
          </Col>
          <Col md="4">
            <img alt="..." className="d-block" src={require('../../assets/img/tom-klein.jpg')} />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Position:</strong> Actor <br />
                <strong>Experience:</strong> 1 year
              </div>
              <div className="description">
                Artist is a term applied to a person who engages in an activity deemed to be an art.
                An artist also may be defined unofficially as "a person who expresses him- or
                herself through a medium". He is a descriptive term applied to a person who engages
                in an activity deemed to be an art. An artist also may be defined unofficially as "a
                person who expresses him- or herself through a medium".
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round mr-1"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round mr-1"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: '',
    caption: '',
    src: '1',
  },
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Lucy</h1>
          </Col>
          <Col md="4">
            <img alt="..." className="d-block" src={require('../../assets/img/lucy.jpg')} />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Position:</strong> CEO <br />
                <strong>Experience:</strong> 7 years
              </div>
              <div className="description">
                Artist is a term applied to a person who engages in an activity deemed to be an art.
                An artist also may be defined unofficially as "a person who expresses him- or
                herself through a medium". He is a descriptive term applied to a person who engages
                in an activity deemed to be an art. An artist also may be defined unofficially as "a
                person who expresses him- or herself through a medium".
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round mr-1"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round mr-1"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: '',
    caption: '',
    src: '2',
  },
];

class AboutUs extends React.Component {
  state = {
    activeIndex: 0,
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add('about-us');
  }
  componentWillUnmount() {
    document.body.classList.remove('about-us');
  }
  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header">
            <img alt="..." className="path path3" src={require('../../assets/img/path3.png')} />
            <svg className="hidden">
              {/* All deco shapes */}
              <defs>
                <path
                  d="M292,517.91s-15.55-56.18-82.16-38.13C144.29,497.54,155.58,363.16,162,313.12S224.89,15.59,387.71,4.49,515.3,133.38,499.28,174.22s-48.71,85.09,11,218.26S455,582.74,430.06,588.37,339.71,621.12,292,517.91Z"
                  id="deco2"
                />
              </defs>
            </svg>
            <div className="content-center">
              <Container>
                <Row className="align-items-center text-left">
                  <Col className="ml-auto mr-auto" lg="6" md="8" xs="12">
                    <h1 className="title">
                      Membership designed for <br />
                      <strong className="text-warning">today's leaders</strong>
                    </h1>
                    <p className="description">
                      Powervel is for C-Suite, senior executives, and outstanding VPs who want to
                      develop their leadership, increase their influence, and inspire others.
                    </p>
                    {/* <Row className='row-input'>
                      <Col className='mt-1' md='8' xs='6'>
                        <Input
                          aria-label='Your email'
                          id='signupSrEmail'
                          name='email'
                          placeholder='Your email'
                          type='email'
                        />
                      </Col>
                      <Col md='4' xs='6'>
                        <Button color='warning'>Get Started</Button>
                      </Col>
                    </Row> */}
                  </Col>
                  <Col className="ml-auto mr-auto" lg="6" md="8" xs="12">
                    {/* SVG Illustration */}
                    <figure className="ie-non-standard-hero-shape">
                      <svg
                        className="injected-svg js-svg-injector"
                        data-img-paths={
                          '[{&quot;targetId&quot;: &quot;#imageShape1&quot;, &quot;newPath&quot;: &quot;' +
                          require('../../assets/img/executives.jpeg') +
                          '&quot;},{&quot;targetId&quot;: &quot;#imageShape2&quot;, &quot;newPath&quot;: &quot;' +
                          require('../../assets/img/executives.jpeg') +
                          '&quot;}]'
                        }
                        data-parent="#SVGNonStandardHeroShape"
                        style={{ enableBackground: 'new 10 12 878.9 907' }}
                        viewBox="10 12 878.9 907"
                        x="0px"
                        y="0px"
                        xmlSpace="preserve"
                      >
                        <g>
                          <defs>
                            <path
                              d="M299.27,772.72s-24.67-76.08-131.42-51.33C62.82,745.74,81.48,563.56,92,495.71S193.94,92.18,454.77,76.46,658.58,250.62,632.75,306s-78.37,115.53,16.76,295.77-89.33,258.1-129.36,265.84S375.3,912.41,299.27,772.72Z"
                              id="firstShape"
                            />
                          </defs>
                          <clipPath id="secondShape">
                            <use style={{ overflow: 'visible' }} xlinkHref="#firstShape" />
                          </clipPath>
                          <g clipPath="url(#secondShape)">
                            <image
                              height="1000"
                              id="imageShape1"
                              style={{ overflow: 'visible' }}
                              transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                              width="1000"
                              xlinkHref={require('../../assets/img/executives.jpeg')}
                            />
                          </g>
                        </g>
                        <g>
                          <defs>
                            <path
                              d="M741.49,643q-2.58-.31-5.17-.4c-12-.45-46.58-14.37-79.24-71.85-17.81-31.35-47.32-96.41-37.88-167.21,7.84-58.79,38.38-97.1,48.34-130.64,24.82-83.61,66.62-77.07,98-77.07,15.93,0,31.1,14.82,39.21,26.39,11.55,16.48,19.72,46.24-9.1,93.32-60.66,99.07,14.09,139.33-.93,239.68C781.72,641.8,750,644,741.49,643Z"
                              id="thirdShape"
                            />
                          </defs>
                          <clipPath id="fourthShape">
                            <use style={{ overflow: 'visible' }} xlinkHref="#thirdShape" />
                          </clipPath>
                          <g clipPath="url(#fourthShape)" transform="matrix(1 0 0 1 0 0)">
                            <image
                              height="1000"
                              id="imageShape2"
                              style={{ overflow: 'visible' }}
                              transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                              width="1000"
                              xlinkHref={require('../../assets/img/executives.jpeg')}
                            />
                          </g>
                        </g>
                      </svg>
                    </figure>
                    {/* End SVG Illustration */}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="features-4">
            <Container>
              <Row className="align-items-center">
                <Col className="mr-auto text-left" lg="3">
                  <h1 className="title">The Powervel platform!</h1>
                </Col>
                <Col className="p-sm-0" lg="8">
                  <Row>
                    <Col md="6">
                      <div className="info info-primary">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-satisfied" />
                        </div>
                        <h4 className="info-title">Community Groups</h4>
                        <p className="description">
                          Join groups based on industry, role, and identity to talk
                          business-critical themes and trends.
                        </p>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="info info-primary">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-chat-33" />
                        </div>
                        <h4 className="info-title">Member Meetups</h4>
                        <p className="description">
                          At virtual and in-person gatherings, connect with leaders from across the
                          Powervel community.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="info info-primary">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-user-run" />
                        </div>
                        <h4 className="info-title">Executive Coaching</h4>
                        <p className="description">
                          A confidential advisory board to help you think through issues.
                        </p>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="info info-primary">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-world" />
                        </div>
                        <h4 className="info-title">Networks</h4>
                        <p className="description">
                          Connect with like-minded leaders to share ideas and expand your network.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <section className="section section-lg">
            <section className="section">
              <img alt="..." className="path" src={require('../../assets/img/path4.png')} />
              <Container>
                <Row className="row-grid justify-content-between">
                  <Col className="mt-lg-5" md="5">
                    <Row>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-trophy text-warning" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">10</CardTitle>
                                  <p />
                                  <p className="card-category">Awards</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats upper bg-default">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-single-02 text-white" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">350</CardTitle>
                                  <p />
                                  <p className="card-category">Members</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-components text-info" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">1+</CardTitle>
                                  <p />
                                  <p className="card-category">Years</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-credit-card text-success" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">5%</CardTitle>
                                  <p />
                                  <p className="card-category">Growth</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <div className="pl-md-5">
                      <h1>
                        Some of our <br />
                        Stats!
                      </h1>
                      <br />
                      <p>
                        Powervel CEO members saw an average annual revenue increase of 3.2% in 2021,
                        while nonmembers with comparable businesses saw a revenue decrease of 3.0%.
                      </p>
                      <br />
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </section>

          <div className="testimonials-3">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <h2 className="title">What Clients Say</h2>
                </Col>
              </Row>
              <Row>
                <div className="carousel slide carousel-team" id="carousel-testimonials3">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <Card className="card-blog card-background" data-animation={true}>
                        <div
                          className="full-background"
                          style={{
                            backgroundImage: 'url(' + require('../../assets/img/group.jpeg') + ')',
                          }}
                        />
                        <CardBody>
                          <div className="content-bottom ml-auto">
                            <Button
                              className="btn-round btn-simple btn-neutral"
                              color="default"
                              href="/apply"
                            >
                              Apply
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </div>

          <DemoFooter />
        </div>
      </>
    );
  }
}

export default AboutUs;
