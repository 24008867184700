import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import clientReducer from './clientReducer';
import isLoadingReducer from './loading.reducer';
import qaReducer from './qaReducer';
import paginator from './paginatorReducer';

export default combineReducers({
  session: sessionReducer,
  client: clientReducer,
  sessionReducer,
  isLoadingReducer,
  qaReducer,
  paginator,
});
